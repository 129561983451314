 /* ----------header--css----------- */
 
 #disbled {
     pointer-events: none !important;
 }
 
 .headermainwrapper {
     background: rgb(35, 203, 230);
     background: linear-gradient(180deg, rgba(35, 203, 230, 1) 0%, rgba(4, 174, 201, 1) 100%);
     position: relative;
 }
 
 .header_colcontainer {
     display: flex;
     justify-content: space-between;
     align-items: center;
 }
 
 .header_leftblock {
     position: relative
 }
 
 .header_leftblock .logowrapper {
     padding: 0px 0px;
     background-color: #fff;
     position: relative;
     left: 0;
     top: 0;
     width: auto;
 }
 
 .header_leftblock .logowrapper img {
     max-width: 100%;
     display: block;
 }
 
 .header_leftblock_subwrapper {
     height: 100%;
     display: flex;
     align-items: center;
 }
 
 .header_leftblock_subwrapper h3 {
     font-size: 21px;
     color: #fff;
     font-weight: var(--font-semibold)
 }
 
 .header_centerblock ul {
     list-style: none;
     padding: 0;
     margin: 0;
     display: flex;
     justify-content: center;
     align-items: center
 }
 
 .header_centerblock li a,
 .header_centerblock li button,
 .logout_class {
     cursor: pointer;
     text-decoration: none;
     font-size: 22px;
     color: #fff;
     font-weight: var(--font-medium);
     padding: 0px 0px;
     display: block;
     position: relative;
     text-transform: capitalize;
     margin: 0 18px;
 }
 
 .header_centerblock li a.active,
 .header_centerblock li button.active,
 .header_centerblock li a:hover {
     /* background: rgb(10,30,115);background: linear-gradient(0deg, rgba(10,30,115,1) 0%, rgba(18,49,178,1) 100%); */
     color: #191818;
 }
 
 .header_centerblock li a.active::after,
 .header_centerblock li button.active::after {
     content: "";
     width: 100%;
     height: 4px;
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     bottom: -6px;
     background-color: #deff00
 }
 
 .header_centerblock li a span,
 .header_centerblock li button span {
     position: relative
 }
 
 .header_centerblock li a i,
 .header_centerblock li button i {
     position: absolute;
     right: -25px;
     top: 61%;
     transform: translateY(-50%)
 }
 
 .header_rightblock_number {
     height: 100%;
     display: flex;
     align-items: center;
     padding: 0 14px
 }
 
 .header_rightblock_number a {
     text-decoration: none;
     font-size: 30px;
     color: #3d3d3d;
     font-weight: var(--font-semibold);
     background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/iwc_call_logo.webp);
     background-repeat: no-repeat;
     background-position: left center;
     padding: 20px 0;
     padding-left: 80px
 }
 
 .responsive_menu {
     display: none
 }
 
 .header_centerblock li {
     position: relative;
 }
 
 .header_centerblock .dropdownmenu {
     position: absolute;
     background: #1696b5;
     min-width: 100%;
     display: none;
     z-index: 1;
     box-shadow: 0 0 8px rgb(0 0 0 / 60%)
 }
 
 .header_centerblock li:hover .dropdownmenu,
 .dropdownmenu:hover .dropdownmenu {
     display: block;
 }
 
 .header_centerblock .dropdownmenu li a {
     padding: 8px 16px;
     white-space: nowrap
 }
 
 .header_centerblock .dropdownmenu li a::after {
     display: none;
 }
 
 .header_centerblock .dropdownmenu li {
     border-bottom: 1px solid #3aa7c1
 }
 
 .header_centerblock .dropdownmenu li:nth-last-child(1) {
     border: 0;
 }
 
 .hide_button {
     display: none;
 }


 /* ================Admin-Dashboard Menu Toggle========= */
.admin_topNav button:nth-child(3){
    display: flex;
    align-items: center;
  }
  .toggle_Container .toggle_button{
    display: none;
    cursor: pointer;
  }
  .toggle_button button {
    background: #0d1011ba;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }
  .toggle_button button svg {
    color: #fff;
  }
  .name_drop {display: flex;align-items: center;}
  @media only screen and (max-width: 991.98px) {
    .toggle_Container{
      display: flex;
      justify-content: center;
      margin-right: 15px;
    }
    .toggle_Container .toggle_button{
      display: block;
    }
    .admin_topNav{
      display: none !important;
    }
    .navbar_off{
      display: none !important;
    }
    .navbar_on{
      display: block !important;
    }
  }
  /* ================Admin-Dashboard Menu Toggle========= */
 
 @media(max-width:1820px) {
     .header_centerblock li a {
         font-size: 17px
     }
 }
 
 @media(max-width:1648px) {
     .header_rightblock_number a {
         font-size: 24px;
     }
     .header_rightblock_number a {
         background-size: 50px;
         padding-left: 65px;
         font-size: 17px;
     }
     .header_leftblock .logowrapper {
         width: 240px;
     }
 }
 
 @media(max-width:1440px) {
     .header_centerblock li a {
         margin: 0 10px;
     }
 }
 
 @media (max-width: 1328px) {
     .header_centerblock li a {
         font-size: 14px;
     }
     .header_centerblock li a {
        margin: 0 8px;
    }
 }
 
 @media (max-width: 1199px) {
     .header_colcontainer {
         flex-wrap: wrap;
     }
     .headermainwrapper .common_width {
         width: 100% !important;
     }
     .responsive_menu {
         display: flex;
         background: rgb(0, 193, 255);
         background: linear-gradient(0deg, rgba(0, 193, 255, 1) 0%, rgba(0, 246, 255, 1) 100%);
         align-items: center;
         padding: 10px;
         margin-left: 7px;
         color: #fff;
         border-radius: 8px;
         cursor: pointer;
     }
     .header_centerblock {
         display: none;
         order: 3;
         width: 100%;
         background: rgb(34 176 199);
     }
     .header_centerblock li a.active::after,
     .header_centerblock li button.active::after {
         display: none;
     }
     .header_centerblock ul {
         flex-wrap: wrap;
     }
     .header_rightblock {
         display: flex;
         align-items: center;
         padding-right: 20px;
     }
     .showdiv {
         display: block;
     }
     .header_centerblock li {
         width: 100%;
         text-align: center;
     }
     .header_centerblock li a,
     .header_centerblock li button {
         font-size: 19px;
         padding: 8px 5px;
         border-bottom: 1px solid #0a7da1;
         margin: 0;
     }
     .header_leftblock {
         padding-left: 20px;
     }
     /* .hide_button {display: block;} */
     .hide_button {
         display: none;
     }
 }
 
 @media (max-width: 540px) {
     .header_leftblock .logowrapper {
         width: 222px;
     }
     .responsive_menu {
         padding: 7px;
     }
     .header_leftblock .logowrapper {
         width: 180px;
     }
     .header_rightblock_number a {
         background-size: 35px;
         padding-left: 45px;
         font-size: 15px;
     }
 }
 
 @media (max-width: 440px) {
     .header_colcontainer {
         align-items: center;
         justify-content: center;
         flex-direction: column;
     }
     .header_leftblock {padding-left: 0px;padding-top: 14px;}
     .header_rightblock {width: 92%;justify-content: space-between;padding-right: 0;}
     .header_rightblock_number {padding: 0;}
 }
 /* ----------header--css----------- */
 
 .error-page {
     color: #042241;
     height: 70vh;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
 }
 
 .error-page h1 {
     margin: 0;
     color: #042241;
     font-size: 120px;
     font-weight: bold;
     text-align: center;
 }
 
 .admin_topNav {
     background: rgb(125, 155, 185);
     background: linear-gradient(180deg, rgba(125, 155, 185, 1) 0%, rgba(84, 105, 126, 1) 100%);
     padding: 15px;
     border-radius: 0px;
     text-align: center;
 }
 
 .admin_topNav button {
     color: #fff;
     font-size: 16px;
     padding: 5px 12px;
     text-transform: capitalize;
 }
 
 .admin_topNav a {
     color: #fff;
     font-size: 16px;
 }
 
 .footerblock {
     font-size: 14px;
     color: #737476;
     text-align: center;
     padding: 20px 5px;
     background-color: #191818;
 }
 
 .admin_topbody {
     background: rgb(226, 226, 226);
     background: linear-gradient(180deg, rgba(226, 226, 226, 1) 0%, rgba(255, 255, 255, 1) 100%);
     padding: 5px 15px;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     align-items: center;
 }
 
 .admin_topbody .adminlogo {
     width: auto;
     max-width: 180px;
 }
 
 .topuserblock {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
 }
 
 .topuserblock h1 {
     margin: 0;
     padding: 0 15px;
     font-size: 18px;
 }
 
 .topuserblock .PersonIcon {
     background: #25ace3;
     color: #fff;
     width: 30px;
     height: 30px;
     border-radius: 100px;
     padding: 5px;
 }
 
 .topuserblock Button {
     margin: 0;
     padding: 0;
     min-width: inherit;
 }
 
 .topuserblock .userarrowicon {
     background-color: #1ea5de;
     color: #fff;
     width: 20px;
     height: 20px;
     border-radius: 100px;
     padding: 0px;
     margin: 0;
 }
 
 .admin_topNav button.active,
 .admin_topNav button:hover {
     background: #22b2e7;
 }
 
 .responsive_navBar {
     display: none;
 }
 
 .footerblock span {
     margin: 0 5px;
 }
 
 .footerblock a {
     margin: 0 5px;
     display: inline-block;
     color: #737476;
 }
 
 .footerblock a:hover {
     color: #22b2e7;
 }
 
 .footerModal .MuiDialog-paper {
     margin: 0;
     padding: 0!important;
     overflow: inherit;
     border: 10px solid #0070af!important;
     background-color: #fff;
     position: relative!important;
     border-radius: 10px;
 }
 
 .footerModal .MuiTypography-h6 {
     background: #0070af;
     font-size: 20px;
     color: #fff;
     margin: 0;
     padding: 0 15px 8px 15px;
 }
 
 .modallogo {
     display: block;
     max-width: 90%;
     margin: 10px auto;
 }
 
 .comingsoonText {
     min-width: 500px;
     max-width: 80%;
     text-align: center;
     text-transform: capitalize;
     font-size: 24px;
     padding: 15px 0;
 }
 
 .submenu_wrp .MuiPopover-paper {
     background: rgb(125, 155, 185);
     background: linear-gradient(180deg, rgba(125, 155, 185, 1) 0%, rgba(84, 105, 126, 1) 100%);
 }
 
 .error-page h1 {
     margin: 0;
     color: #042241;
     font-size: 120px;
     font-weight: bold;
     text-align: center;
 }
 

 .admin_topNav {
     background: rgb(35, 203, 230);
     background: linear-gradient(180deg, rgba(35, 203, 230, 1) 0%, rgba(5, 175, 202, 1) 100%);
     padding: 15px;
     border-radius: 0px;
     text-align: center;
     display: flex;
    justify-content: center;
 }
 
 .admin_topNav button {
     color: #fff;
     font-size: 16px;
     padding: 5px 12px;
     text-transform: capitalize;
     margin: 0 2px;
 }
 
 .admin_topNav a {
     color: #fff;
     font-size: 16px;
 }
 
 .footerblock {
     font-size: 14px;
     color: #4b4c50;
     text-align: center;
     padding: 20px 5px;
     background-color: #e6eaeb;
 }
 
 .admin_topbody {
     background: rgb(149 164 167);
     background: linear-gradient(180deg, rgb(247 247 247) 0%, rgb(179 182 183) 100%);
     padding: 10px 20px;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     align-items: center;
 }
 
 .admin_topbody .adminlogo {
     width: auto;
     max-width: 200px;
 }
 
 .topuserblock {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
 }
 
 .topuserblock h1 {
     margin: 0;
     padding: 0 15px;
     font-size: 18px;
     display: inline-block;
     max-width: 180px;
     white-space: nowrap;
     overflow: hidden !important;
     text-overflow: ellipsis;
 }
 
 .topuserblock .PersonIcon {
     background: #0d1011ba;
     color: #fff;
     width: 30px;
     height: 30px;
     border-radius: 100px;
     padding: 5px;
 }
 
 .topuserblock Button {
     margin: 0;
     padding: 0;
     min-width: inherit;
 }
 
 .topuserblock .userarrowicon {
     background-color: #0d1011ba;
     color: #fff;
     width: 20px;
     height: 20px;
     border-radius: 100px;
     padding: 0px;
     margin: 0;
 }
 
 .admin_topNav button.active,
 .admin_topNav button:hover {
     background: #002836;
 }
 
 .responsive_navBar {
     display: none;
 }
 
 .footerblock span {
     margin: 0 5px;
 }
 
 .footerblock a {
     margin: 0 5px;
     display: inline-block;
     color: #4b4c50;
 }
 
 .footerblock a:hover {
     color: #22b2e7;
 }
 
 .footerModal .MuiDialog-paper {
     margin: 0;
     padding: 0 !important;
     overflow: inherit;
     border: 10px solid #0070af !important;
     background-color: #fff;
     position: relative !important;
     border-radius: 10px;
 }
 
 .footerModal .MuiTypography-h6 {
     background: #0070af;
     font-size: 20px;
     color: #fff;
     margin: 0;
     padding: 0 15px 8px 15px;
 }
 
 .modallogo {
     display: block;
     max-width: 90%;
     margin: 10px auto;
 }
 
 .comingsoonText {
     min-width: 500px;
     max-width: 80%;
     text-align: center;
     text-transform: capitalize;
     font-size: 24px;
     padding: 15px 0;
 }
 
 .submenu_wrp .MuiPopover-paper {
     background: rgb(125, 155, 185);
     background: linear-gradient( 180deg, rgba(125, 155, 185, 1) 0%, rgba(84, 105, 126, 1) 100%);
 }
 
 .submenu_wrp .MuiPopover-paper a {
     font-size: 16px;
     font-weight: 500 !important;
     color: #fff;
 }
 
 .submenu_wrp .active a {
     color: #1bbbff;
 }
.mini_logo {display: none;}

@media only screen and (max-width: 1199px) {
.mini_logo {display: block;background: #fff;border-radius: 50%;margin-right: 15px;}
.admin_topNav button {
    font-size: 14px;
    padding: 5px 9px;
}

}

 
 @media only screen and (max-width: 991.98px) {
     .admin_topNav {
         padding: 0;
     }
     .admin_topNav button {
         display: block;
         width: 100%;
         border-bottom: solid 1px #fff;
         border-radius: 0;
         font-size: 16px;
         padding: 5px 9px;
     }
     .responsive_navBar {
         display: block;
         padding-left: 20px;
     }
     .admin_topRight {
         display: flex;
         flex-wrap: wrap;
         justify-content: center;
         align-items: center;
     }
     .responsive_navBar svg {
         background: #25ace3;
         color: #fff;
         border-radius: 5px;
         font-size: 33px;
         padding: 5px 6px;
         cursor: pointer;
     }
     .comingsoonText {
         min-width: inherit;
     }
 }
 
 @media only screen and (max-width: 850px) {
    .admin_topbody{
        flex-direction: column;
    }
 }
 @media only screen and (max-width: 640.98px) {
     .admin_topbody {
         display: block;
     }
     .admin_topbody .adminlogo {
         display: block;
         margin: 0 auto;
         margin-bottom: 15px;
         max-width: 90%;
     }
     .admin_topRight {display: block;}
     /* .topuserblock {justify-content: space-between;} */
     .Dashbaord_banner_wrapper {margin-top: 10px;}
     .Dashbaord_banner_text {margin-top: 15px;}
     .name_drop{
         margin-top: 10px;
     }
 }
 
 @media only screen and (max-width: 480.98px) {
     .admin_topRight {
         display: block;
     }
     .responsive_navBar {
         display: block;
         padding-left: 0;
         text-align: center;
         margin-top: 15px;
     }
 }
 @media only screen and (max-width: 440px) {
    .mini_logo {display: none;}
    .Dashbaord_TopPH a{
        font-size: 20px;
    }

 }


