.mainContentWrapper {
    background: #e5fafd;
    min-height: 100vh;
    overflow: hidden;
}

.connectedemail {
    background-color: #052958;
    padding: 10px 20px;
    font-size: 24px;
    box-shadow: 2px 2px 8px #bac1c5;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.connectedemail h2 {
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    text-transform: capitalize;
}

.calenderinfo {
    font-size: 18px;
    line-height: 24px;
}

.calenderinfo label {
    font-size: 26px;
    color: #dcfe00;
    font-weight: 700;
}

.calenderinfo p strong {
    padding-right: 10px;
    display: inline-block;
    color: #dcfe00;
}

.buttonsetToolbar {
    margin: 15px;
    background: #fff;
    padding: 1%;
    box-shadow: 0 0 2px #e9e9e9;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.buttonsetToolbar .MuiButtonBase-root {
    width: 23%;
    /* background: #00b9fe; */
    padding: 12px 0;
    /* background: linear-gradient( 180deg, rgba(0, 185, 254, 1) 0%, rgba(1, 106, 161, 1) 100%); */
    background: rgb(70, 85, 91);
    background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%);
    border: solid 8px #baeefb;
    box-shadow: none;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    line-height: 20px;
    margin: 0.5%;
}

.buttonsetToolbar .MuiButtonBase-root a {
    color: #fff;
}


/* .form_wrapper {
    margin: 15px;
    background: #2baae4;
    background: linear-gradient( 180deg, rgba(43, 170, 228, 1) 0%, rgba(2, 119, 175, 1) 100%);
    padding: 15px;
    box-shadow: 2px 2px 8px #bac1c5;
    border-radius: 4px;
    border: solid 15px #baeefb;
} */

.form_wrapper_con {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.form_wrapper_con .MuiInputBase-input {
    padding: 12px 14px;
}

.form_wrapper_con .MuiSelect-select {
    padding: 12px 14px;
}

.form_wrapper_con .MuiFormControl-root .MuiInputLabel-root {
    top: -4px;
    display: none;
}

.form_wrapper_con .MuiFormControl-root {
    width: 100%;
    margin-bottom: 0;
}

.form_wrapper_con .MuiOutlinedInput-root {
    border-radius: 0;
}

.form_wrapper_con .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
}

.form_wrapper_con .MuiOutlinedInput-root {
    background-color: #fff;
    width: 100%;
    margin: 0;
    border: solid 1px #ccc;
}

.form_wrapper_con .form_group {
    width: 400px;
    margin-right: 10px;
}

.form_wrapper_con label {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
}

.form_wrapper_con .MuiBox-root {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
}

.subBTNWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: end;
}

.subBTNWrapper .subBTN {
    width: 50px;
    height: 50px;
    margin: 0 2px;
}

.subBTNWrapper .subBTN button {
    background: #012b59;
    color: #baeefb;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    padding: 0;
    min-width: inherit;
}

.subBTNWrapper .subBTN button svg {
    width: 35px;
    height: 35px;
}

.listCounttext {
    text-align: center;
    font-size: 18px;
    color: rgb(255, 255, 255);
    padding: 15px;
    background: #088195;
    margin: 15px;
    border-radius: 4px;
    margin-bottom: 0;
}

.calender_body {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.loadMoreSection {
    margin: 0 15px 30px;
}

.loadMoreSection button {
    /* background-color: #00baf7 !important; */
    background: rgb(70, 85, 91);
    background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%);
    color: #fff;
    font-size: 20px;
    padding: 10px 20px;
    min-width: 140px;
    font-weight: 700;
    margin: 5px auto;
    display: block;
}


/*************/

.No_slot_div {
    text-align: center!important;
    color: #00b9fe!important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: rgb(70, 85, 91);
    background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%); */
    background: rgb(149 164 167);
    background: linear-gradient(180deg, rgb(223 223 223) 0%, rgb(121 121 121) 100%);
    padding: 30px ;
    font-size: 30px;
    font-weight: 600;
    flex-direction: column;
}
.No_slot_div img{
    width: 190px;
    height: auto;
}
 
.calcelSlot_box .calender_block{
    /* box-shadow: 1px 1px 13px #00000091;
    background-image: linear-gradient( 174.2deg, rgb(255 255 255 / 72%) 7.1%, rgb(255 255 255 / 68%) 67.4% ); */
    border: none;
    width: 40%;
    background: rgb(183,236,250);
    background: linear-gradient(180deg, rgba(183,236,250,1) 0%, rgba(28,109,182,1) 100%);
    box-shadow: 1px 1px 5px 5px rgb(55 78 163);
}
.calcelSlot_box .calender_block p strong {
    /* color: #3c3c3c; */
    background-color: #ffea00;
    color: transparent;
    -webkit-background-clip: text;
}
.calcelSlot_box .calender_block p{
    /* color: #0087ae; */
    color: #000000;
    text-shadow: none;
}
.calender_block {
    margin: 0.5%;
    border-radius: 0px;
    box-shadow: 1px 1px 9px #00000017;
    width: 30%;
    transition: transform 0.2s;
    background: #ffffff;
    padding: 0.6%;
    border: 1px solid #f5f5f5;
  background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_training_BG.webp) no-repeat 0 0;
  background-size: cover; border-radius: 5px;
}

.calender_block:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.calender_block_con {
    height: 100%;
}

.calender_block_con_body {
    padding: 15px;
}

/* .calender_block h2 {
    font-size: 24px;
    color: #148ec7;
    font-weight: 700;
}

.calender_block h2::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #0070af;
    margin: 10px 0;
} */

.calender_block p {
    margin: 0;
    padding: 5px 0;
    font-size: 18px;
    color: #333;
}

.calender_block p span {
    display: inline-block;
    padding-right: 5px;
    font-weight: 700;
}

.calender_block.bookNowModal {
    width: 100% !important;
    background: none;
    box-shadow: none;
    border: none !important;
    word-break: normal;
}

.calender_block.bookNowModal:hover {
    transform: none !important;
}

.addemailbox {
    position: relative;
}

.addemailbox input {
    margin: 5px 0;
}

.addemail {
    padding-right: 10% !important;
}

.addemailBTN {
    width: 10%;
    height: 36px;
    position: absolute;
    right: 0;
    bottom: 5px;
    border: none;
    background: #08507f;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
}

.modalblock .MuiBox-root input {
    width: 98%;
    height: 34px;
    background: #f3efef;
    border: solid 1px #d1c0c0;
    box-shadow: none;
    padding: 0px 0px 0px 4px;
    color: #000;
    padding-right: 0px !important;
}

.slotModal.modalblock .MuiBox-root {
    padding-top: 35px;
    position: relative;
}

.modalblock .MuiBox-root h3 {
    text-align: center;
    margin-top: 10px;
}

.bookingModal.modalblock .MuiBox-root {
    padding: 0px !important;
}

.bookingModal.modalblock .modalBox {
    max-height: 82vh;
    overflow-y: auto;
    border: 0px !important;
    height: 100% !important;
    padding: 15px !important;
}

.bookingModal .css-1ehqt7t{ width: 600px; max-width: 88%;}

.bookingModal.modalblock .closeModalBtn,
.deleteModal.modalblock .closeModalBtn,
.custommodal.modalblock .CloseModal {
    position: absolute;
    background: #2076d2 !important;
    top: -20px;
    width: 30px;
    color: #fff;
    height: 30px;
    right: -20px;
    min-width: auto;
    border-radius: 50%;
    z-index: 9;
}

.modalSUBbtn_wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.modalSUBbtn_wrapper .modalSUBbtn {
    background-color: #1976d2 !important;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    color: #fff;
    margin: 2px;
}

.mainContentWrapper .buttonsetToolbar button a {
    background: rgb(253, 253, 253);
    background: linear-gradient(180deg, rgba(253, 253, 253, 1) 0%, rgba(194, 195, 196, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bookingModal h2 {
    color: #0785d1;
    display: block;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
}

.bookingModal .bookNowModal {
    background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(4, 5, 6, 1) 100%);
    border: solid 1px #fff!important;
    margin: 10px 0!important;
    width: auto!important;
    max-width: inherit!important; padding: 15px!important;
}

.bookingModal textarea {
    resize: none; padding: 1%; width:98%!important; border: none!important; border-radius: 0px!important;
}

.calender_block button {
    background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%);
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin: 5px 0;
    min-width: 80px;
    padding: 5px 15px;
}

.form_wrapperSlot {
    background: linear-gradient( 180deg, rgba(0, 185, 254, 1) 0%, rgba(1, 106, 161, 1) 100%);
    margin: 0 15px;
    border-radius: 5px;
    padding: 10px;
}


/*************/
@media only screen and (max-width: 1460px) {
    .calcelSlot_box .calender_block{
        width: 60% !important;
    }
}
@media only screen and (max-width: 1199.98px) {
    .calender_block {
        width: 46%!important;
        padding: 1%!important;
    }
    .calcelSlot_box .calender_block{
        width: 70% !important;
    }
 
}

@media only screen and (max-width: 1060.98px) {
    .form_wrapper_con {
        width: 100%;
        margin: 0 auto;
    }
    .form_wrapper_con .form_group {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }
    .form_wrapper {
        padding: 15px;
    }
}

@media only screen and (max-width: 991.98px) {
    .connectedemail {
        display: block;
        text-align: center;
    }
    .calenderinfo {
        margin-top: 15px;
    }
    .buttonsetToolbar .MuiButtonBase-root {
        width: 49%;
        margin: 0.5%;
    }
}

@media only screen and (max-width: 767.98px) {
    .calcelSlot_box .darktheme{
        display: flex !important;
    }
    .calender_body {
        display: block;
    }
    .calender_block {
        margin: 0 auto!important;
        width: 90%!important;
        margin-top: 15px!important;
        padding: 1.5%!important;
    }
    .calender_block:first-of-type {
        margin-top: 0;
    }
   
}

@media only screen and (max-width: 640.98px) {
    .buttonsetToolbar .MuiButtonBase-root {
        width: 98%;
        margin: 5px auto;
    }
    .calcelSlot_box .calender_block {
        width: 90% !important;
    }

}
@media only screen and (max-width: 600.98px){
.buttonsetToolbar{
margin: 0;
}
}
@media only screen and (max-width: 400.98px) {
    .form_wrapper_con .MuiDateRangePickerInput-root {
        display: block;
    }
    .form_wrapper_con .MuiBox-root {
        text-align: center;
        margin: 10px 0;
    }
    .calender_block {
        width: 88%;
        padding: 2%;
    }
}

.sun-editor-editable {
    height: 450px!important
}

.adminformbody {
    margin: 10px 20px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
}

.adminformbody .formInline {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap
}

.adminformbody .formInline label {
    color: #000
}

.adminformbody .formInline .inputBlock4line {
    flex: 1 0 24%;
    margin: 10px 0.3%
}

.adminformbody .formInline .inputBlock3line {
    flex: 1 0 32%;
    margin: 10px 0.3%
}

.adminformbody .formInline .inputBlock2line {
    flex: 1 0 48%;
    margin: 10px 0.3%
}

.adminformbody .formInline .inputBlock1line {
    flex: 100%;
    margin: 10px 0.3%
}

.adminformbody .formInline .inputBlock .MuiFormControl-root.MuiTextField-root {
    background-color: #fff;
    width: 100%;
    margin-bottom: 0;
    border: solid 1px #ccc;
    height: 48px
}

.adminformbody .formInline .inputBlock .MuiFormControl-root.MuiTextField-root input,
.adminformbody .formInline .inputBlock .MuiSelect-select {
    padding: 12px 14px
}

.adminformbody .formInline .inputBlock .MuiFormControl-root .MuiInputLabel-root {
    top: -4px;
    display: none
}

.adminformbody .formInline .inputBlock .MuiFormControl-root {
    width: 100%;
    margin-bottom: 0
}

.adminformbody .formInline .inputBlock .MuiOutlinedInput-root {
    border-radius: 0
}

.adminformbody .formInline .inputBlock .MuiOutlinedInput-notchedOutline {
    border: 0!important
}

.adminformbody .formInline .inputBlock .MuiInputLabel-shrink {
    display: none!important
}

.adminformbody .formInline .usertypebox .MuiSelect-select {
    padding: 8px 14px;
    height: 31px
}

.adminformbody .formInline .usertypebox .MuiFormControl-root {
    background-color: #fff;
    width: 100%;
    margin: 0;
    border: solid 1px #ccc
}

.adminformbody .formInline .passwordinput .MuiFormControl-root {
    background-color: #fff;
    width: 100%;
    margin: 0;
    border: solid 1px #ccc
}

.adminformbody .formInline .passwordinput .MuiFormControl-root input {
    padding: 12px 14px
}

.adminformbody .formInline .addressbox {
    flex: 0 0 99.4%;
    margin: 10px 0.3%
}

.adminformbody .formInline .addressbox textarea {
    background-color: #fff;
    width: 97.5%;
    margin-bottom: 0;
    border: solid 1px #ccc!important;
    resize: none;
    min-height: 500px!important;
    color: black;
    padding: 10px 1%;
    outline: none!important
}

.Formsubmit_button {
    display: block;
    overflow: hidden;
    margin-top: 10px
}

.Formsubmit_button button {
    background: rgb(70, 85, 91);
    background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%);
    color: #d5d6d6;
    font-size: 20px;
    padding: 10px 20px;
    min-width: 140px;
    font-weight: 700;
    margin: 5px
}

.Formsubmit_button button:first-of-type {
    margin-right: 0
}

.adminformbody input:-webkit-autofill,
.adminformbody input:-webkit-autofill:hover,
.adminformbody input:-webkit-autofill:focus,
.adminformbody textarea:-webkit-autofill,
.adminformbody textarea:-webkit-autofill:hover,
.adminformbody textarea:-webkit-autofill:focus,
.adminformbody select:-webkit-autofill,
.adminformbody select:-webkit-autofill:hover,
.adminformbody select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0
}

.adminformbody .css-1wc848c-MuiFormHelperText-root .Mui-error {
    color: #d32f2f;
    bottom: -2px;
    font-size: 11px;
    position: absolute
}

.adminformbody .MuiFormHelperText-root {
    color: #ffffff;
    background: #a90000;
    font-size: 11px;
    line-height: 15px;
    margin: 4px 0 0;
    text-align: center  ;
    /* position: absolute */
}

.addedittext {
    font-size: 28px;
    width: 80%;
    background-color: #002236;
    padding: 15px 5px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    margin-bottom: 25px;
    border-radius: 0 0 25px 25px
}

.searchlabel {
    width: 100%;
    background-color: #002236;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    margin-bottom: 5px
}

.btnabel {
    width: 100%;
    background-color: #002236;
    padding: 5px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    margin-bottom: 5px
}

.Image_Box #list h1 {
    font-size: 18px!important;
}

.Image_Box .blog_img_wrpr .upload-container .MuiButtonBase-root {
    width: 36px!important;
    height: 36px!important;
}

.inputBlockday {
    flex: 12%!important;
    margin: 0 0.3%!important;
    margin-bottom: 16px!important;
    background: #fff;
    padding: 0 0.5%;
    border: solid 1px #ccc;
}

@media only screen and (max-width: 1199.98px) {
    .adminformbody .formInline {
        display: block
    }
    .adminformbody .inputBlockday {
        padding: 0 5px;
        width: 130px;
        margin-bottom: 4px!important;
        display: inline-block;
    }
    .bookingModal .css-1ehqt7t {
        max-width: 80%!important;
    }
}

.formInline input {
    width: 100%;
}



/* .admin_topNav button:nth-child(3){
    display: flex;
    align-items: center;
  } */
  .toggle_Icon .toggle_Iconwrp{
    display: none;
    cursor: pointer;
  }
  .toggle_Iconwrp button {
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }
  .toggle_Iconwrp button svg {
    color: #dcfe00;
  }
  /* .name_drop {display: flex;align-items: center;} */

  @media only screen and (max-width: 460.98px) {
    .toggle_Icon{
      display: flex;
      justify-content: start;
      padding: 15px 10px;
    }
    .toggle_Icon .toggle_Iconwrp{
      display: block;
    }
    .buttonsetToolbar{
      display: none !important;
    }
    .menu_off{
      display: none !important;
    }
    .menu_on{
      display: block !important;
    }
    .creatEdit_form{
        margin: 0 auto 15px !important;
    }
  }