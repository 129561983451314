 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&family=Roboto:wght@700;900&display=swap');
 /* --------------frontend_css----------------------------------- */
 
 body {
     margin: 0!important;
     font-family: 'Poppins', sans-serif !important;
     min-height: 100vh;
 }
 
 code {
     font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace
 }
 
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 p {
     padding: 0;
     margin: 0;
     font-weight: normal;
 }
 
 :root {
     --font-light: 300;
     --font-regular: 400;
     --font-medium: 500;
     --font-semibold: 600;
     --font-bold: 700;
     --font-extrabold: 800;
 }
 
 a {
     text-decoration: none;
 }
 
 a,
 button {
     cursor: pointer;
 }
 
 .common_width {
     width: 1800px;
     margin: 0 auto;
 }
 
 .min_height_wrp {
     min-height: 100vh;
 }
 
 .common_banner_mainwrapper {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/iwc_common_banner_bg.webp);
     background-repeat: no-repeat;
     background-size: cover;
     background-position: left top;
 }
 
 .common_banner_padding {
     min-height: 180px;
     padding: 85px 0;
     display: flex;
     align-items: center;
     background-size: auto 100%;
     background-position: right bottom;
     background-repeat: no-repeat !important;
 }
 
 .common_banner_textwrapper h1 {
     font-weight: var(--font-bold);
 }
 
 .font93 h1 {
     font-size: 93px;
     line-height: 110px;
     font-weight: 700;
     text-transform: uppercase;
     background-color: #24b0d8;
     color: transparent;
     text-shadow: 2px 2px 3px rgb(61 145 185 / 30%), -1px -1px #37c4e7;
     -webkit-background-clip: text;
 }
 
 .font93 h2 {
     font-size: 60px;
     font-weight: var(--font-semibold);
     color: #000;
     line-height: 74px;
 }
 
 .textwrapper p {
     font-size: 24px;
     line-height: 32px;
     color: #fff;
     font-weight: var(--font-medium);
 }
 
 .common_title {
     padding: 15px 20px;
     background-size: cover;
     background-position: top left;
 }
 
 .common_title h2 {
     font-size: 48px;
     line-height: 62px;
     color: #fff
 }
 
 .violet_common_title {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/violet_common_title.webp);
     background-repeat: no-repeat;
     background-size: 100% 100%;
 }
 
 .light_green_common_title {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/light_green_common_title.webp);
     background-repeat: no-repeat;
     background-size: cover;
 }
 
 .blue_common_title {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/blue_common_title.webp);
     background-repeat: no-repeat;
     background-size: cover;
 }
 
 .title_bar {
     padding: 20px 35px;
 }
 
 .title_bar h2 {
     color: #fff;
     font-weight: var(--font-semibold);
     text-transform: uppercase;
     font-size: 48px;
     line-height: 56px;
 }
 
 .block_listwrapper p {
     font-size: 25px;
     line-height: 32px;
     font-weight: var(--font-medium);
     color: #000;
     position: relative;
     padding: 7px 0;
     padding-left: 30px
 }
 
 .block_listwrapper p::after {
     content: '';
     height: 23px;
     width: 23px;
     position: absolute;
     left: 0;
     top: 14px;
     background-repeat: no-repeat;
 }
 
 .deep_blue_bullet_point p::after {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/deep_blue_bullet.webp);
 }
 
 .blue_bullet_point p::after {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/blue_bullet.webp);
 }
 
 .wht_bullet_point p::after {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/white_bullet_img.webp);
 }
 
 .lgtgreen_bullet_point p::after {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/light_green_bullet.webp);
     top: 13px;
 }
 
 .left_image_andtext {
     background-repeat: no-repeat;
     background-position: left top;
     position: relative;
     background-size: auto 100%
 }
 
 .left_image_andtext .textandimagewrapper {
     background-repeat: no-repeat;
     background-position: left 100% top;
     position: relative;
     background-size: 54% 100%;
 }
 
 .left_image_andtext .textandimagewrapper_padding {
     padding-left: 740px
 }
 
 .block_padding {
     padding: 60px 0;
 }
 
 .right_image_andtext {
     background-repeat: no-repeat;
     background-position: right top;
     position: relative;
     background-size: auto 100%
 }
 
 .right_image_andtext .textandimagewrapper {
     background-repeat: no-repeat;
     background-position: left top;
     position: relative;
     background-size: 54% 100%;
 }
 
 .right_image_andtext .textandimagewrapper_padding {
     padding-right: 740px
 }
 
 .yellow_txt {
     text-decoration: none;
     color: #deff00;
 }
 
 .common_padding {
     padding: 60px 0;
 }
 
 .flex_wrapper {
     display: flex;
     justify-content: space-between;
 }
 
 .font40 p {
     font-size: 40px;
     line-height: 48px;
     color: #fff;
 }
 
 .left_img_wrp {
     width: 68%;
 }
 
 .left_img_wrp img {
     width: 100%;
     display: block;
 }
 
 .right_title_wrp {
     width: 30%;
 }
 
 .font48 h2 {
     font-size: 48px;
     line-height: 53px;
     color: #fff;
     font-weight: 600;
 }
 
 .font36 h3 {
     font-size: 36px;
     line-height: 42px;
     color: #fff;
     font-weight: 600;
 }
 
 .font30 h3 {
     font-size: 30px;
     line-height: 38px;
     color: #fff;
     font-weight: 600;
 }
 
 .font24 h3 {
     font-size: 24px;
     line-height: 30px;
     color: #fff;
     font-weight: 500;
 }
 
 .font25 p {
     font-size: 25px;
     line-height: 31px;
     color: #fff;
 }
 
 .textwrapper .font18_para {
     font-size: 18px;
     line-height: 24px;
     color: #fff;
     font-weight: 500;
 }
 
 .inner_padding {
     padding: 30px 35px;
     padding-right: 33%;
 }
 
 .right_img_left_text {
     background-repeat: no-repeat;
     background-size: cover;
     background-position: left top;
 }
 
 .title_wrp h2 {
     font-size: 48px;
     line-height: 40px;
     color: #fff;
     font-weight: 600;
 }
 
 .title_wrp p {
     font-size: 35px;
     line-height: 35px;
     color: #fff;
     font-weight: 500;
     margin-top: 10px;
 }
 
 .box_font25 h3 {
     font-size: 25px;
     color: #050000;
     font-weight: 500;
     position: relative;
     padding-left: 35px;
     margin-top: 10px;
 }
 
 .box_font25 h3::before {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/blue_small_square.webp);
     background-repeat: no-repeat;
     position: absolute;
     left: 0;
     top: 10px;
     width: 20px;
     height: 20px;
     content: '';
 }
 
 .sky_bg_text h2 {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/blue_sky_gradient_bg.webp);
     background-repeat: no-repeat;
     background-size: cover;
     padding: 15px 25px;
 }
 
 .column_2 {
     column-count: 2;
 }
 
 @media only screen and (max-width:1820px) {
     .common_width {
         width: 96%;
     }
 }
 
 @media only screen and (max-width:1728px) {
     .font93 h2 {
         font-size: 52px;
         line-height: 66px;
     }
 }
 
 @media only screen and (max-width:1500px) {
     .font93 h1 {
         font-size: 65px;
         line-height: 90px;
     }
     .font93 h2 {
         font-size: 44px;
         line-height: 58px;
     }
     .textwrapper p {
         font-size: 21px;
         line-height: 28px;
     }
     .common_padding {
         padding: 40px 0;
     }
     .title_bar h2 {
         font-size: 32px;
         line-height: 38px;
     }
 }
 
 @media only screen and (max-width:1199px) {
     .font93 h1 br {
         display: none;
     }
     .common_banner_padding {
         min-height: auto;
     }
     .title_bar {
         padding: 20px 20px;
     }
     .title_bar h2 {
         font-size: 30px;
         line-height: 38px;
     }
     .common_padding {
         padding: 25px 0;
     }
     .common_banner_textwrapper {
         text-align: center;
     }
     .font93 h1 {
         font-size: 38px;
         line-height: 55px;
     }
     .block_padding {
         padding: 25px 0;
     }
     .left_image_andtext .textandimagewrapper,
     .right_image_andtext .textandimagewrapper {
         background-size: cover !important;
     }
     .left_image_andtext .textandimagewrapper_padding,
     .right_image_andtext .textandimagewrapper_padding {
         padding: 0 !important;
     }
 }
 
 @media only screen and (max-width:767px) {
     .block_padding {
         padding: 15px 0;
     }
     .common_padding {
         padding: 15px 0;
     }
     .font93 h2 {
         font-size: 34px;
         line-height: 38px;
     }
 }
 
 @media only screen and (max-width:599px) {
     .common_width {
         width: 92%;
     }
 }
 /* --------------frontend_css----------------------------------- */
 /* .banner_flex {display: flex;justify-content: space-between;}
.main_wrapper {background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/home_banner_bg.webp);background-size: cover;background-position: left bottom;background-repeat: no-repeat;padding-top: 260px;;}
.left_text_wrapper h1 {font-size: 92px;font-weight: 700;color: #e1e7ea;text-transform: uppercase;    line-height: 92px;}
.left_text_wrapper h2 {font-size: 51px;font-weight: 700;color: #e1e7ea;line-height: 50px;}
.left_text_wrapper p {font-size: 22px;font-weight: 400;color: #e1e7ea;line-height: 30px;margin-top: 20px;}
.left_text_wrapper {width: 42%;}
.middle_para p {font-size: 20px;font-weight: 500;color: #e1e7ea;margin-top: 0;}
.middle_para {background: linear-gradient(90deg, rgba(7,10,34,1) 33%, rgba(7,10,34,0.4374124649859944) 66%);
  padding: 15px;border: 1px solid #00c8f5;    margin: 45px 0 20px 0;
  border-radius: 5px;}
.banner_form_wrapper {width: 45%; } */
 /*--------------------------------------AddTrainingCategory CSS START----------------------------------------------------------*/
 
 .formheader h1 {
     background-color: #052958;
     color: #fff;
     font-size: 24px;
     padding: 15px;
     text-align: center;
     /* border-radius: 5px; */
 }
 
 .formbody {
     margin: 20px;
 }
 
 .backendTableInline_addform {
     align-items: stretch;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }
 
 .backendTableInline_addform .trainingname {
     flex: 1 0 24%;
     margin: 10px .3%;
 }
 
 .trainingcata .trainingname .MuiFormControl-root {
     width: 100%;
 }
 
 .backendTableInline_addform .priority {
     flex: 1 0 24%;
     margin: 10px .3%;
 }
 
 .trainingcata .priority .MuiFormControl-root {
     width: 100%;
 }
 
 .admin_addForm .backendTableInline_addform .description .MuiFormControl-root textarea {
     resize: none;
     border: 1px solid rgba(0, 0, 0, 0.23);
     ;
     outline: none;
 }
 
 .trainingcata .description .MuiFormControl-root {
     width: 100%;
 }
 /* .admin_addForm .formheader{
  padding: 5px 15px;
} */
 
 .admin_addForm .submitbuttonwrapper {
     margin: 0 auto 20px;
 }
 
 .admin_addForm .submitbuttonwrapper button {
     background: rgb(70, 85, 91);
     background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(4, 5, 6, 1) 100%);
     color: rgb(255, 255, 255);
     font-weight: 600;
     font-size: 18px;
     padding: 10px;
     margin: 0 5px;
 }
 
 .admin_addForm .MuiFab-root:hover {
     background-color: #052958;
 }
 
 .admin_addForm .MuiFab-root {
     background-color: #052958;
 }
 /* .backendTableInline_addform .lessonname {
     flex: 1 0 98%;
 } */
 
 .backendTableInline_addform .lessonname .MuiFormControl-root {
     width: 100%;
 }
 
 .backendTableInline_addform .sunEditor {
     flex: 1 0 98%;
 }
 
 .backendTableInline_addform .status {
     flex: 1 0 98%;
 }
 
 .backendTableInline_addform .status {
     flex: 1 0 98%;
 }
 
 .backendTableInline_addform .description {
     flex: 1 0 98%;
 }
 
 .admin_addForm .formLabel,
 .admin_addForm .upload_data_wrpr,
 #CPTICDAddEditForm .formInline .list_search_item {
     margin: 10px .3%;
 }
 
 .upload_data_wrpr .blog_img_wrpr #list h1 {
     background-color: #052958;
     padding: 10px;
     border-radius: 5px;
     color: white;
 }
 
 .admin_addForm thead .MuiTableSortLabel-icon {
     opacity: 1!important;
     color: rgb(255 255 255)!important;
 }
 
 .userformWrapperClass .passwordBlock-Profile {
     display: flex;
     align-items: stretch;
     justify-content: space-between;
     flex-wrap: wrap;
     background: #f5f5f5;
     padding: 15px 45px;
     border-radius: 10px;
     flex-direction: column !important;
 }
 
 .passwordBlock {
     width: 50% !important;
     margin: 15px auto !important;
 }
 
 .upload_data_wrpr button {
     color: #fff;
     font-size: 20px;
     padding: 10px 20px;
     min-width: 140px;
     font-weight: 700;
     margin: 5px;
     background: rgb(70, 85, 91);
     background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%);
 }
 /* #chngePass_profl{
  width: 60%;
} */
 /* ==========================Login Css=========================== */
 
 .loginMain_wrpr {
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_login_bnr.png) no-repeat, #e9f7fe;
     background-size: 50% 100%, auto;
     position: relative;
 }
 
 .login_body {
     /* background: rgb(85, 194, 218);
  background: linear-gradient(
    180deg,
    rgba(85, 194, 218, 1) 0%,
    rgba(168, 148, 236, 1) 100%
  ); */
     position: relative;
     width: 100vw;
     min-height: 100vh;
     padding: 100px;
     width: 98%;
     margin: 0 auto;
 }
 /* .login_page_main {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
} */
 
 .login_bodyBG {
     width: 100vw;
     height: 100vh;
     position: relative;
 }
 
 .login_bodyBG_block1 {
     background-color: #22a3dea1;
     border-radius: 100%;
     width: 222px;
     height: 222px;
     position: absolute;
     left: 15px;
     /* top: -210px; */
     bottom: 50px;
     /* animation-name: example;
  animation-duration: 10s;
  animation-delay: 2s; */
     /* transform-style: preserve-3d; */
 }
 
 .login_bodyBG_block2 {
     background-color: rgb(40, 184, 233, 0.5);
     border-radius: 100%;
     width: 126px;
     height: 126px;
     position: absolute;
     left: 9%;
     bottom: 33%;
     /* animation-name: example;
  animation-duration: 10s;
  animation-delay: 2s; */
     /* transform-style: preserve-3d; */
 }
 /* @keyframes example {
  0%   {width: 350px; 
    height: 350px; }
  25%  {
    width: 400px; 
    height: 400px;
  }
  50%  {display: none;}
  75%  { left:0px; top:200px;}
  100% {left:0px; top:0px;}
} */
 
 .login_bodyBG_block3 {
     background-color: #56e0ffad;
     border-radius: 100%;
     width: 320px;
     height: 320px;
     position: absolute;
     left: 31%;
     top: 32%;
     /* animation-name: example;
  animation-duration: 10s;
  animation-delay: 2s; */
     /* transform-style: preserve-3d; */
 }
 
 .login_body .loginLogoSection {
     position: relative;
     background: rgba(0, 221, 246, 0);
     box-shadow: -2px 0px 15px 1px rgb(81 81 81 / 50%);
     overflow: hidden;
 }
 
 .login_body .loginLogoSection .logo-Wrpr {
     /* margin: 280px 140px; */
     position: relative;
     z-index: 1;
     width: 70%;
     overflow: hidden;
     margin: 233px auto;
     display: flex;
     justify-content: center;
     box-shadow: 1px 1px 12px rgb(81 81 81 / 50%);
 }
 
 .login_body .loginLogoSection .logo-Wrpr img {
     /* box-shadow: -2px 0px 15px 1px rgb(81 81 81 / 50%); */
     z-index: 999;
     position: relative;
     width: 100%;
 }
 
 .login_body .login_Section {
     background: #ffffff;
     box-shadow: 6px 0px 15px 1px rgb(81 81 81 / 50%);
     padding: 100px 60px;
 }
 
 .login_Section .MuiLinearProgress-root {
     top: -90px;
 }
 
 .login_Section {
     /* margin: 128px 105px 128px 0; */
     position: relative;
     z-index: 2;
     padding: 130px 98px;
     margin-left: 0;
 }
 
 .login_body input:-internal-autofill-selected {
     background-color: -internal-light-dark( rgb(255, 255, 255), rgb(255, 255, 255)) !important;
     -webkit-box-shadow: 0 0 0 35px #f7fbfa inset !important;
     color: #abb3b3 !important;
 }
 
 input:-webkit-autofill:focus,
 input:-webkit-autofill:active {
     -webkit-box-shadow: 0 0 0 35px #f7fbfa inset !important;
     background-color: #000 !important;
 }
 
 input:-internal-autofill-selected {
     background-color: -internal-light-dark( rgb(255, 255, 255), rgb(255, 255, 255)) !important;
     -webkit-box-shadow: 0 0 0 35px #ffffff inset !important;
 }
 
 .login_Section .MuiInputBase-root {
     background: #f7fbfa;
     font-size: 23px;
 }
 
 .login_main_wrpper .forgot_password {
     text-decoration: none;
     color: #010101;
     font-weight: 500;
     font-size: 25px;
 }
 
 .loginLogo {
     display: block;
     margin: 0 auto;
     width: 250px;
     max-width: 100%;
 }
 
 .login_Section h1 {
     margin: 0;
     padding: 15px 0;
     font-size: 81px;
     line-height: 80px;
     text-align: center;
     font-weight: bold;
     background: rgb(9, 223, 239);
     background: linear-gradient(180deg, rgba(9, 223, 239, 1) 21%, rgba(7, 183, 239, 1) 53%, rgba(0, 100, 176, 1) 53%, rgba(0, 100, 176, 1) 100%);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     margin-bottom: 15px;
 }
 
 .login_Section .MuiFormControl-root {
     background: #fff;
     border-radius: 4px;
     box-shadow: 1px 1px 5px 5px rgba(204, 204, 204, 1);
     margin-top: 30px;
 }
 
 .login_Section .MuiFormControl-root label {
     color: #abb3b3;
     font-size: 23px;
     font-weight: 500;
 }
 
 .login_Section svg {
     color: #a7a7a7;
 }
 
 .login_Section fieldset {
     border: none;
     outline: none;
 }
 
 .login_Section p.Mui-error,
 #passwordUpdateForm .formGroup .MuiFormControl-root .MuiFormHelperText-contained {
     background: #d32f2f;
     color: #fff;
     margin: -2px 0 0 0;
     padding: 4px 4px 0 4px;
     border-radius: 0 0 2px 2px;
 }
 
 .login_main_wrpper {
     position: relative;
     z-index: 9;
 }
 
 .login_main_wrpper .passBttn {
     font-size: 32px;
     color: #fff;
     font-weight: bold;
     text-transform: capitalize;
     width: 100%;
     border-radius: 0;
     margin: 60px auto 5px;
     display: block;
     box-shadow: 0 4px 15px 0 rgb(94 180 218 / 75%);
     background: rgb(77, 212, 241);
     background: linear-gradient(135deg, rgba(77, 212, 241, 1) 0%, rgba(12, 118, 227, 1) 100%);
     font-weight: 600;
     color: #fff;
     text-align: center;
     border: none;
     text-shadow: -1px -2px 2px rgba(25, 136, 230, 0.65);
     background-size: 100% 100%;
     moz-transition: all .4s ease-in-out;
     -o-transition: all .4s ease-in-out;
     -webkit-transition: all .4s ease-in-out;
     transition: all .4s ease-in-out;
 }
 
 .login_main_wrpper .passBttn:hover {
     background-position: 100% 0;
     moz-transition: all .4s ease-in-out;
     -o-transition: all .4s ease-in-out;
     -webkit-transition: all .4s ease-in-out;
     transition: all .4s ease-in-out;
 }
 
 .frgt_passWrpr {
     margin-top: 10px;
 }
 
 .login_Section .logo-Wrpr {
     display: none;
 }
 /* .login_main_wrpper */
 /* .forgetPass{
  background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_login_bnr.png) no-repeat, #e9f7fe;
  background-size: 50% 100%, auto;
  min-height: 100vh;
} */
 /* .forgetPass .login_main_wrpper{
  width: 700px;
  margin: 0 auto;
} */
 /* .forgetPass .login_Section{
  padding: 0;
} */
 /* .forgetPass .emailBox{
  width: 100%;
} */
 
 .forgetPass .emailBox .MuiGrid-container {
     justify-content: center;
     margin-top: 20px;
 }
 
 .forgetPass .login_Logo {
     overflow: hidden;
 }
 
 .forgetPass .login_Logo img {
     display: block;
     margin: 50px auto 0;
     width: 500px;
 }
 /* .Reset_pass .login_Section{
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */
 
 .Reset_pass .list_search {
     width: 100%;
 }
 
 .Reset_pass .list_search #passwordUpdateForm .MuiFormControl-root {
     width: 100%;
     margin-top: 30px !important;
 }
 
 .Reset_pass .submitbuttonwrapper {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }
 
 .Reset_pass .submitbuttonwrapper button {
     font-size: 32px;
     color: #fff;
     font-weight: bold;
     text-transform: capitalize;
     width: 49%;
     border-radius: 0;
     margin: 60px 0 0;
     display: block;
     box-shadow: 0 4px 15px 0 rgb(94 180 218 / 75%);
     background: rgb(77, 212, 241);
     background: linear-gradient(135deg, rgba(77, 212, 241, 1) 0%, rgba(12, 118, 227, 1) 100%);
     font-weight: 600;
     color: #fff;
     text-align: center;
     border: none;
     text-shadow: -1px -2px 2px rgb(25 136 230 / 65%);
 }
 
 .MuiSnackbar-root,
 .MuiSnackbar-root {
     /* bottom: 185px !important; */
     /* top:unset !important; */
     transform: translate(-50%) !important;
     left: 50% !important;
     justify-content: center !important;
 }
 
 .MuiSnackbar-root .MuiSnackbarContent-root,
 .MuiSnackbar-root .MuiAlert-root {
     min-height: 75px;
     display: flex;
     align-items: center;
     justify-content: center;
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_header/IWC_home_block1_heading_bg.webp);
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center;
     border: 1px solid #ffffff;
     font-size: 22px;
     text-transform: capitalize;
     color: #fff!important;
 }
 /* .MuiSnackbar-root .MuiSnackbarContent-root.MuiSnackbarContent-message{
    text-transform: capitalize;
 } */
 
 .MuiSnackbar-root .MuiAlert-icon {
     color: #fff !important;
 }
 
 .loginMain_wrpr .login_Section form .MuiInputLabel-shrink {
     display: none !important;
 }
 /* ===============================backend css================================= */
 
 .sun-editor-editable {
     height: 450px!important
 }
 
 .adminformbody {
     margin: 15px auto;
     width: 90%;
     border-radius: 10px;
 }
 
 .adminformbody .formInline {
     display: flex;
     align-items: stretch;
     justify-content: space-between;
     flex-wrap: wrap;
     background: #f5f5f5;
     padding: 30px;
     border-radius: 10px;
 }
 
 .adminformbody .formInline label {
     color: #000
 }
 
 .adminformbody .formInline .inputBlock {
     flex: 1 0 30%;
     margin: 5px 0.3%
 }
 
 .adminformbody .formInline .inputBlock4line {
     flex: 1 0 24%;
     margin: 5px 0.3%
 }
 
 .adminformbody .formInline .inputBlock3line {
     flex: 1 0 32%;
     margin: 5px 0.3%
 }
 
 .adminformbody .formInline .inputBlock2line {
     flex: 1 0 48%;
     margin: 5px 0.3%
 }
 
 .adminformbody .formInline .inputBlock1line {
     flex: 100%;
     margin: 5px 0.3%
 }
 
 .adminformbody .formInline .inputBlock .MuiFormControl-root.MuiTextField-root {
     background-color: #fff;
     width: 100%;
     margin-bottom: 0;
     border: solid 1px #ccc;
     height: 48px
 }
 
 .adminformbody .formInline .inputBlock .MuiFormControl-root.MuiTextField-root input,
 .adminformbody .formInline .inputBlock .MuiSelect-select {
     padding: 12px 14px
 }
 
 .adminformbody .formInline .inputBlock .MuiFormControl-root .MuiInputLabel-root {
     top: -4px;
     display: none
 }
 
 .adminformbody .formInline .inputBlock .MuiFormControl-root {
     width: 100%;
     margin-bottom: 0
 }
 
 .adminformbody .formInline .state .MuiFormControl-root {
     background-color: #fff;
     width: 100%;
     margin-bottom: 0;
     border: solid 1px #ccc;
     height: 48px;
 }
 
 .adminformbody .formInline .state .MuiFormControl-root .MuiInputLabel-root {
     display: block;
 }
 
 .adminformbody .formInline .inputBlock .MuiOutlinedInput-root {
     border-radius: 0
 }
 
 .adminformbody .formInline .inputBlock .MuiOutlinedInput-notchedOutline {
     border: 0!important
 }
 
 .adminformbody .formInline .inputBlock .MuiInputLabel-shrink {
     display: none!important
 }
 
 .adminformbody .formInline .usertypebox .MuiSelect-select {
     padding: 8px 14px;
     height: 31px
 }
 
 .adminformbody .formInline .usertypebox .MuiFormControl-root {
     background-color: #fff;
     width: 100%;
     margin: 0;
     border: solid 1px #ccc
 }
 
 .adminformbody .formInline .passwordinput .MuiFormControl-root {
     background-color: #fff;
     width: 100%;
     margin: 0;
     border: solid 1px #ccc
 }
 
 .adminformbody .formInline .passwordinput .MuiFormControl-root input {
     padding: 12px 14px
 }
 
 .adminformbody .formInline .addressbox {
     flex: 0 0 100%;
     margin: 3px 0.3%
 }
 
 .adminformbody .formInline .addressbox textarea {
     background-color: #fff;
     width: 97.5%;
     margin-bottom: 0;
     border: solid 1px #ccc!important;
     resize: none;
     min-height: 30px!important;
     height: 100px !important;
     color: black;
     padding: 10px 1%;
     outline: none!important
 }
 
 .Formsubmit_button {
     display: block;
     overflow: hidden;
     margin-top: 0px
 }
 
 .Formsubmit_button button {
     background-color: #00baf7!important;
     color: #fff;
     font-size: 20px;
     padding: 10px 20px;
     min-width: 140px;
     font-weight: 700;
     margin: 5px
 }
 
 .Formsubmit_button button:first-of-type {
     margin-right: 0
 }
 
 .passwordBlock-Profile .Formsubmit_button button:last-of-type {
     margin-left: 0;
 }
 
 .adminformbody input:-webkit-autofill,
 .adminformbody input:-webkit-autofill:hover,
 .adminformbody input:-webkit-autofill:focus,
 .adminformbody textarea:-webkit-autofill,
 .adminformbody textarea:-webkit-autofill:hover,
 .adminformbody textarea:-webkit-autofill:focus,
 .adminformbody select:-webkit-autofill,
 .adminformbody select:-webkit-autofill:hover,
 .adminformbody select:-webkit-autofill:focus {
     border: none;
     -webkit-text-fill-color: #000;
     -webkit-box-shadow: 0 0 0 1000px #fff inset;
     transition: background-color 5000s ease-in-out 0
 }
 
 .adminformbody .css-1wc848c-MuiFormHelperText-root .Mui-error {
     color: #d32f2f;
     bottom: -2px;
     font-size: 11px;
     position: absolute
 }
 /* .adminformbody .MuiFormHelperText-root{    color: #ffffff;
   bottom: -10px;
   font-size: 15px;
   position: absolute;
   background: #07b0cb;
   width: 100%;
   margin: 0px 0 0 0;
   text-align: center;
} */
 
 .addedittext {
     font-size: 28px;
     width: 90%;
     background-color: #002236;
     padding: 15px 5px;
     margin: 0 auto;
     text-align: center;
     color: #fff;
     margin-bottom: 25px;
     border-radius: 0 0 25px 25px
 }
 
 .searchlabel {
     width: 100%;
     background-color: #002236;
     margin: 0 auto;
     text-align: center;
     color: #fff;
     margin-bottom: 5px
 }
 
 .btnabel {
     width: 100%;
     background-color: #002236;
     padding: 5px;
     margin: 0 auto;
     text-align: center;
     color: #fff;
     margin-bottom: 5px
 }
 /* .addressbox .sun-editor{ height: 200px;} */
 
 .Image_Box #list h1 {
     font-size: 18px!important;
 }
 
 .Image_Box .blog_img_wrpr .upload-container .MuiButtonBase-root {
     width: 36px!important;
     height: 36px!important;
 }
 
 .inputBlockday {
     flex: 6%!important;
     margin: 0 0.3%!important;
     margin-bottom: 16px!important;
     background: rgb(229 250 253);
     padding: 0 0.5%;
     border: solid 1px #ccc;
 }
 
 .adminformbody .formInline .singleCheckbox {
     margin: 0 0.3%;
 }
 
 .adminformbody .formInline .inputBlock .MuiFormControl-root .MuiAutocomplete-root {
     margin: 0;
 }
 
 .adminformbody .formInline .inputBlock .MuiFormControl-root .MuiAutocomplete-root .MuiOutlinedInput-root {
     padding: 0;
 }
 
 .inputBlockday .MuiTypography-root,
 .inputBlockday span input,
 .inputBlockday .MuiCheckbox-root {
     color: rgb(1, 1, 1) !important;
 }
 
 .adminformbody .blank_Heading {
     flex: 28% 1!important;
 }
 
 .adminformbody #trainingAddEditForm .upload_data_wrpr h2 {
     background: #14bdd8;
     border-radius: 5px;
     text-align: center;
     padding: 10px;
 }
 
 .adminformbody .image_container .Cont_imgWrap h1 button {
     width: 100%;
 }
 /* <--------------Add CPT/ICD Form CSS------------> */
 
 #CPTICDAddEditForm .formInline .list_search_item .MuiFormControl-root {
     width: 100%;
 }
 
 #CPTICDAddEditForm .formInline .list_search_item .MuiFormControl-root .MuiOutlinedInput-root {
     padding: 0;
 }
 
 #CPTICDAddEditForm .formInline .list_search_item .MuiFormControl-root input {
     padding: 16.5px 14px;
 }
 
 #CPTICDAddEditForm .formInline .list_search_item .MuiFormControl-root .MuiAutocomplete-root {
     margin: 0;
 }
 /* <--------------Add CPT/ICD Form CSS------------> */
 
 @media only screen and (max-width: 1515px) {
     .adminformbody .blank_Heading {
         flex: 10% 1!important;
     }
 }
 
 @media only screen and (max-width: 1199.98px) {
     .adminformbody .formInline {
         display: block
     }
     .adminformbody .inputBlockday {
         padding: 0 5px;
         width: 130px;
         margin-bottom: 4px!important;
         display: inline-block;
     }
 }
 
 @media only screen and (max-width: 600px) {
     .Formsubmit_button {
         display: flex;
         justify-content: center;
     }
     .adminformbody .formInline {
         padding: 15px;
     }
     .Formsubmit_button button {
         font-size: 16px;
         padding: 10px 10px;
         min-width: 90px;
     }
 }
 
 @media only screen and (max-width: 400px) {
     .Formsubmit_button button {
         font-size: 14px;
         line-height: 20px;
         padding: 5px;
         width: auto !important;
         min-width: 68px;
     }
 }
 /* ===============================backend css================================= */
 
 @media only screen and (max-width: 1460px) {
     /* .login_body .loginLogoSection .logo-Wrpr img{
width: 70%;
  } */
     .login_Section h1 {
         font-size: 61px;
         line-height: 60px;
     }
 }
 
 @media only screen and (max-width: 1199px) {
     .loginMain_wrpr .login_body {
         flex-direction: column;
         padding: 50px;
         align-items: center;
         justify-content: center;
     }
     .loginLogoSection,
     .login_Section {
         max-width: 100% !important;
     }
     .login_body .loginLogoSection .logo-Wrpr {
         margin: 50px auto;
         display: none;
     }
     .login_body .loginLogoSection .logo-Wrpr img {
         width: 300px;
     }
     .login_body .login_Section {
         margin: 0;
         padding: 30px;
     }
     .login_Section .logo-Wrpr {
         display: block;
     }
     .login_Section .logo-Wrpr img {
         width: 300px;
         display: block;
         margin: 0 auto;
     }
     .loginMain_wrpr {
         background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_login_bnr.png) no-repeat;
         background-size: cover;
     }
 }
 
 @media only screen and (max-width: 900px) {
     /* .forgetPass .login_main_wrpper{
  width: 90%;
} */
     /* .forgetPass .login_Logo img{
  width: 70%;
} */
     /* .forgetPass .login_Section {
  margin-top: -80px;
} */
     .forgetPass .login_Section h1 {
         font-size: 50px;
         line-height: 55px;
         margin-bottom: 0;
     }
     .forgetPass .login_main_wrpper .passBttn {
         margin: 30px auto 5px;
     }
     .login_Section .MuiFormControl-root label {
         font-size: 17px;
     }
     .login_Section .MuiInputBase-root {
         font-size: inherit;
     }
     .Reset_pass .submitbuttonwrapper button {
         font-size: 22px;
         width: 40%;
         margin: 30px auto 0;
     }
     /* #chngePass_profl {
  width: 80%;
} */
     .passwordBlock {
         width: 90% !important;
     }
 }
 
 @media only screen and (max-width: 600px) {
     .login_body .login_Section {
         padding: 20px;
     }
     .login_Section h1 {
         font-size: 40px;
         line-height: 30px;
     }
     .MuiSnackbar-root .MuiSnackbarContent-root,
     .MuiSnackbar-root .MuiAlert-root {
         font-size: 16px;
         min-height: 55px;
     }
 }
 
 @media only screen and (max-width: 480px) {
     .forgetPass .login_Section h1 {
         font-size: 32px;
         line-height: 32px;
     }
     .login_main_wrpper .passBttn {
         font-size: 24px;
         width: 100%;
         margin: 30px auto 5px;
     }
     .login_main_wrpper .forgot_password {
         font-size: 20px;
     }
     /* .forgetPass .login_Section {
    margin-top: -120px;
  } */
     .Reset_pass .submitbuttonwrapper button {
         font-size: 22px;
         width: 40%;
         margin: 30px auto 0;
     }
     .login_body .loginLogoSection .logo-Wrpr img {
         width: 250px;
     }
     .loginMain_wrpr .login_body {
         padding: 20px;
     }
     .login_Section .logo-Wrpr img {
         width: 100%;
     }
 }
 
 .trainingCenter_wrpr .trainingCenter_Banner img,
 .colourTheme_wrpr img {
     display: block;
     width: 100%;
 }
 
 .commonWidth_admin {
     width: 1764px;
     margin: 0 auto;
 }
 
 .trainingCenter_Banner {
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_trng_bnr.png);
     background-repeat: no-repeat;
     background-size: cover;
     background-position: left center;
     /* min-height: 383px; */
 }
 
 .trainingCenter_BannerSub {
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_trncg_hmn.png);
     background-repeat: no-repeat;
     background-size: auto 100%;
     background-position: right bottom;
     /* min-height: 383px;
  display: flex;
  align-items: center; */
 }
 
 .commonWidth_admin .MuiGrid-root {
     margin-left: auto!important;
     margin-right: auto!important;
     margin-top: 0!important;
 }
 
 .colourTheme_wrpr .commonWidth_admin {
     padding: 15px 0!important;
 }
 
 .bannrContntWrpr {
     overflow: hidden;
 }
 
 .bannerContent {
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/W4H_bnrtxt_bg.png);
     background-repeat: no-repeat;
     background-size: cover;
     width: 42%;
     border-radius: 3px;
     border: 1px solid #27ddf5;
     padding: 30px 40px 40px;
     border-right: 0;
     margin: 70px 0;
     /* background-position: right center; */
 }
 
 .bannerContent h1 {
     font-size: 37px;
     font-weight: 600;
     color: #fff;
     /* padding: 30px 40px 40px; */
     line-height: 45px;
 }
 
 .bannerContent h1 span {
     color: #fdf402;
 }
 
 .colourTheme_wrpr {
     background-color: #000;
 }
 
 .commonWidth_admin .MuiGrid-root {
     margin-left: auto!important;
     margin-right: auto!important;
     margin-top: 0!important;
 }
 
 .colourTab .colourLogo {
     width: 16% !important;
 }
 
 .colourTheme_text {
     /* padding-left: 77px; */
     width: 80%;
     text-transform: uppercase;
     display: flex;
     align-items: center;
 }
 
 .colour_boolets {
     margin-right: 14px;
 }
 
 .colour_boolets img {
     display: block;
     width: 100%;
 }
 
 .colourTheme_text p {
     width: 80%;
     font-size: 24px;
     font-weight: 600;
     position: relative;
 }
 
 .colourTab {
     background-color: #fff;
     margin: 0 !important;
     padding: 0;
     max-width: 32.33%!important;
     flex-basis: 32.33%!important;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 10px 20px !important;
     border-radius: 5px;
 }
 
 .colourTab .colourLogo {
     width: 16% !important;
 }
 
 .colourTab:first-child {
     margin-left: 0 !important;
 }
 /* ===========================Responsive Elements ============================ */
 
 @media only screen and (max-width: 1799px) {
     .commonWidth_admin {
         width: 96%!important;
     }
 }
 
 @media only screen and (max-width: 1464px) {
     .trainingCenter_BannerSub {
         background-position: right -110px bottom;
     }
 }
 
 @media only screen and (max-width: 1300px) {
     .trainingCenter_BannerSub {
         background-position: right -163px bottom;
     }
 }
 
 @media only screen and (max-width: 1199px) {
     .commonWidth_admin .MuiGrid-root {
         display: flex!important;
         width: 100%!important;
     }
     .commonWidth_admin .traingcenterList_wrapper {
         width: auto!important;
         padding-left: 0 !important;
         display: block!important;
     }
     .colourTab {
         width: auto;
         margin: 0!important;
         max-width: 100%!important;
         flex-basis: inherit!important;
     }
     .colourTheme_wrpr {
         background-color: #000;
         overflow: hidden;
     }
     .bannerContent {
         width: auto;
         padding: 20px;
         margin: 30px 0;
     }
     .bannerContent h1 {
         font-size: 35px;
     }
     .commonWidth_admin .MuiGrid-root:nth-of-type(2) {
         margin: 10px 0!important;
     }
     .login_bodyBG_block2 {
         left: 18%;
         bottom: 52%;
     }
     .login_bodyBG_block3 {
         left: 53%;
         top: -2%;
     }
     .login_bodyBG_block3_sub {
         left: 270px;
         bottom: 120px;
     }
     .trainingCenter_BannerSub {
         background: none;
         /* min-height: 250px; */
     }
 }
 
 @media only screen and (max-width: 900px) {
     .login_maindiv {
         width: 80%;
     }
     .login_maindiv {
         padding: 15px;
     }
     .bannerContent h1 {
         font-size: 30px;
         line-height: 35px;
     }
 }
 
 @media only screen and (max-width: 767px) {
     .login_bodyBG_block1 {
         /* width: 150px;
    height: 150px;
    left: -40px;
    top: -10px; */
         display: none;
     }
     .login_bodyBG_block2 {
         /* width: 150px;
    height: 150px;
    right: 5%;
    top: -30px; */
         display: none;
     }
     .login_bodyBG_block3 {
         /* width: 150px;
    height: 150px;
    left: -20px;
    bottom: -30px; */
         display: none;
     }
 }
 
 @media only screen and (max-width: 650px) {
     .backLinkHome {
         top: 210px;
     }
 }
 
 @media only screen and (max-width: 640px) {
     .backLink {
         top: 225px;
     }
 }
 
 @media only screen and (max-width: 560px) {
     .bannerContent h1 {
         font-size: 27px;
         line-height: 30px;
     }
     .bannerContent {
         margin: 20px 0;
     }
 }
 
 @media only screen and (max-width: 480px) {
     .commonWidth_admin .MuiGrid-root {
         display: block!important;
     }
     .colourTheme_text {
         width: auto;
         display: flex;
         align-items: center;
         justify-content: center;
     }
     .colourTheme_text p {
         width: auto;
     }
     .colourTheme_text p {
         font-size: 20px;
     }
     .colour_boolets {
         margin-right: 14px;
         width: 30px;
     }
     .bannerContent h1 {
         font-size: 21px;
         line-height: 24px;
     }
     .colourTab .colourLogo {
         display: block;
         margin: 0 auto;
         width: 16%!important;
     }
 }
 
 @media only screen and (max-width: 399px) {
     .bannerContent h1 {
         font-size: 18px;
         line-height: 22px;
     }
     .totalcountshow p {
         font-size: 20px !important;
         line-height: 24px;
     }
     .colourTheme_text p {
         font-size: 16px;
     }
     .colourTab {
         padding: 5px 20px !important;
     }
     .colourTab .colourLogo {
         width: 13%!important;
     }
     .training_right_block .header_block {
         font-size: 18px !important;
         line-height: 22px !important;
     }
     .tittle_wrapper p {
         font-size: 18px !important;
         line-height: 24px !important;
         word-break: break-word;
     }
     .traingcenterdata_wrapper .mad_button_wrapper {
         padding-top: 0;
     }
     .tittle_wrapper,
     .description_wrapper,
     .mad_button_wrapper {
         padding: 5px 15px !important;
     }
 }
 
 .admin_page {
     overflow: hidden;
     min-height: 80vh;
 }
 
 .admin_page #tableTitle {
     font-size: 30px;
     font-weight: 700;
     font-family: 'Poppins', sans-serif;
 }
 
 .admin_page .muiTable .MuiToolbar-root .MuiButtonBase-root {
     color: #fff;
 }
 
 .MuiToolbar-regular {
     background-color: #052958!important;
     padding: 15px;
     font-size: 24px;
     min-height: inherit;
     margin: 0;
     color: #fff;
     min-height: inherit!important;
 }
 
 .MuiTableCell-head {
     padding: 15px 0!important;
     font-size: 14px!important;
     padding: 0!important;
 }
 
 .loader-block {
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 15px 0 0 0;
 }
 
 .loader-block .lds-facebook {
     display: inline-block;
     position: relative;
     width: 80px;
     height: 80px;
 }
 
 .loader-block .lds-facebook div {
     display: inline-block;
     position: absolute;
     left: 8px;
     width: 16px;
     background: #f83701;
     animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
 }
 
 .loader-block .lds-facebook div:nth-child(1) {
     left: 8px;
     animation-delay: -0.24s;
 }
 
 .loader-block .lds-facebook div:nth-child(2) {
     left: 32px;
     animation-delay: -0.12s;
 }
 
 .loader-block .lds-facebook div:nth-child(3) {
     left: 56px;
     animation-delay: 0;
 }
 
 @keyframes lds-facebook {
     0% {
         top: 8px;
         height: 64px;
     }
     50%,
     100% {
         top: 24px;
         height: 32px;
     }
 }
 
 .MuiTableHead-root {
     /* background: #7261e5; */
     background: rgb(6, 61, 113);
     background: linear-gradient(0deg, rgba(6, 61, 113, 1) 0%, rgba(22, 148, 217, 1) 100%);
 }
 
 .MuiTableHead-root th {
     color: #fff !important;
     font-weight: bold;
 }
 
 .admin_page .MuiTableHead-root th,
 .availabilityTable .MuiTableHead-root th {
     text-align: left;
 }
 
 .MuiTableHead-root th:nth-child(1) .MuiCheckbox-root {
     color: #fff !important;
 }
 
 .MuiTableCell-body,
 .MuiTableHead-root th {
     padding: 5px !important;
 }
 
 .listCount {
     margin: 15px auto 0;
 }
 
 .MuiMenu-list a span {
     color: rgba(0, 0, 0, 0.772);
 }
 
 .MuiPagination-root {
     margin: 15px auto !important;
 }
 
 .training_dropDwn .MuiPopover-paper {
     /* top: 154px !important;
    left: 64% !important;
    position: absolute; */
     margin: 32px 0 0 60px;
 }
 
 .addVid_dropDwn .MuiPopover-paper {
     /* top: 154px !important;
    left: 64% !important;
    position: absolute; */
     margin: 32px 0 0 60px;
 }
 
 .modalblock .MuiBox-root,
 .MuiDialog-paperScrollPaper {
     border: 10px solid #0785d1!important;
     background-color: #c8c8c8;
     padding: 15px!important;
     position: relative!important;
     border-radius: 10px;
 }
 
 .modalblock .modalBTN,
 .action_btn_container button {
     background: rgb(70, 85, 91);
     background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%);
     margin: 5px;
     color: #fff;
     font-size: 18px;
     padding: 5px 15px;
     font-weight: bold;
     border-radius: 5px;
     min-width: 80px;
 }
 
 #modal-modal-title,
 .modalblock .headingtext,
 .MuiDialog-paperScrollPaper p {
     font-size: 17px;
     font-weight: 500;
     font-family: "Poppins", sans-serif;
 }
 
 .MuiDialogActions-root {
     display: flex !important;
     justify-content: center !important;
 }
 /* .headingtext{
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
 } */
 
 .closeModalBtn {
     width: 20px!important;
     height: 20px!important;
     background: #0785d1!important;
     color: #fff!important;
     border-radius: 100%!important;
     right: -20px!important;
     position: absolute!important;
     top: -20px!important;
     min-width: inherit!important;
     padding: 15px!important;
 }
 
 .ModalBox_scroll {
     display: block;
     margin: 0 -15px;
     max-height: 65vh;
     overflow: auto;
     -webkit-overflow-scrolling: touch;
     overflow-x: hidden;
 }
 
 .modalblock .MuiBox-root button.modalCloseBtn {
     right: -25px;
     top: -25px;
     background: #0785d1;
     padding: 2px;
     outline: none;
     color: #fff;
     width: auto!important;
     position: absolute;
 }
 
 .modalblock .MuiBox-root ul li {
     list-style: none;
     background: #ebebeb;
     padding: 10px 15px;
 }
 
 .modalblock .MuiBox-root ul li:nth-of-type(odd) {
     background: #9ea2a5d1;
 }
 
 .modalblock .MuiBox-root ul {
     padding: 0;
 }
 
 .reactTableWrapper .MuiPaper-root {
     box-shadow: none;
 }
 
 .lastLogin_Info .MuiBox-root .reactTableWrapper .MuiBox-root {
     border: 0px solid #0785d1!important;
     background-color: #c8c8c800;
     padding: 0px!important;
     border-radius: 14px;
 }
 
 .lastLogin_Info .MuiBox-root .reactTableWrapper .MuiBox-root .MuiTableContainer-root table {
     padding: 5px
 }
 
 .dltStsModal .MuiBox-root {
     width: 486px;
 }
 
 .dltStsModal h2 {
     font-size: 21px !important;
     font-weight: 500;
     line-height: 28px;
     font-family: 'Poppins', sans-serif !important;
     ;
 }
 
 .listCounttext {
     text-align: center;
     font-size: 18px;
     color: rgb(255, 255, 255);
     padding: 15px;
     background: #088195;
     margin: 15px;
     border-radius: 4px;
     margin-bottom: 0;
 }
 
 .celender_Slot .bannerContent {
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_calender_bnr.png);
     border-radius: 5px;
     border: none;
     background-repeat: no-repeat;
     background-size: cover;
     width: 55%;
     display: flex;
     justify-content: space-between;
     padding: 40px;
     margin: 40px 35px;
 }
 
 .celender_Slot .bannerContent h1 {
     margin-bottom: 10px;
 }
 
 .celender_Slot .bannerContent h3 {
     font-size: 24px;
     font-weight: 400;
     color: #000;
 }
 
 .celender_Slot .calendrbanner_logo {
     position: relative;
     z-index: 1;
     width: 35%;
     overflow: hidden;
     display: flex;
     justify-content: center;
     box-shadow: 1px 1px 12px rgb(81 81 81 / 50%);
     border-radius: 5px;
 }
 
 .celender_Slot .calendrbanner_logo img {
     width: 100%;
 }
 
 .celender_Slot .bannerContent_txt {
     width: 62%;
 }
 
 .celender_Slot .trainingCenter_Banner {
     border-radius: 5px;
     margin: 15px;
     overflow: hidden;
     box-shadow: 1px 1px 12px rgb(81 81 81 / 50%);
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_caneldr_bnr.webp);
     background-repeat: no-repeat;
     background-size: cover;
     background-position: left center;
 }
 
 .celender_Slot .trainingCenter_Banner .trainingCenter_BannerSub {
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_calender_hmn.png);
     background-repeat: no-repeat;
     background-size: 56% 100%;
     background-position: right -30px bottom;
 }
 /* ====================USER management listing======================== */
 
 .adminlisttable_v2 table,
 .adminlisttable_v2 th,
 .adminlisttable_v2 td {
     display: block;
     border: none;
     min-width: inherit!important;
 }
 
 .adminlisttable_v2 thead {
     display: block;
     padding: 0;
     border-radius: 0 0 5px 5px;
     background: rgb(6, 61, 113);
     background: linear-gradient(0deg, rgba(6, 61, 113, 1) 0%, rgba(22, 148, 217, 1) 100%);
 }
 
 .adminlisttable_v2 tr {
     display: flex;
     align-items: stretch;
     justify-content: space-between;
     flex-wrap: wrap;
     border-radius: 0!important;
 }
 
 .adminlisttable_v2 tr th {
     text-align: left!important;
     flex: 1 0 31%;
     border-bottom: solid 1px #fff;
     padding: 0.5% 1%!important;
     color: #fff !important;
     font-weight: normal;
 }
 
 .adminlisttable_v2 td .MuiCheckbox-root,
 .adminlisttable_v2 th .MuiCheckbox-root {
     padding: 0!important;
 }
 
 .adminlisttable_v2 th:nth-of-type(2) {
     display: none;
 }
 
 .adminlisttable_v2 tbody {
     display: block;
     border: none;
     padding: 0;
     min-width: inherit!important;
 }
 
 .adminlisttable_v2 tbody tr {
     background: #ebebeb;
     border: solid 1px #e1e0e0;
     border-radius: 5px;
 }
 
 .adminlisttable_v2 tbody tr td {
     flex: 1 0 31%;
     padding: 0.5% 1%!important;
     font-weight: normal;
     border-bottom: solid 1px #fff!important;
 }
 
 .adminlisttable_v2 td:nth-of-type(2) {
     display: none;
 }
 
 .table_Icons tbody tr td button {
     color: rgb(43, 43, 43);
 }
 
 .reactTableWrapper .muiTable .MuiToolbar-root .MuiIconButton-root,
 .reactTableWrapper .conflictingFulldayEventLists .MuiToolbar-root .MuiIconButton-root,
 .reactTableWrapper .replayicon {
     color: #fff;
 }
 
 .selecttraning .MuiFormControl-root {
     margin: 0;
     width: 100%;
 }
 
 .selecttraning .MuiFormControl-root label {
     display: none;
     ;
 }
 
 .admin_addForm .MuiFormControl-root .MuiTextField-root label {
     display: none;
 }
 
 .admin_addForm .MuiFormControl-root fieldset {
     top: 0 !important;
 }
 
 .admin_addForm .MuiFormControl-root fieldset legend {
     display: none!important;
 }
 
 .admin_addForm .adminformbody .formLabel .MuiFormControl-root .MuiTextField-root,
 .admin_addForm .adminformbody .MuiOutlinedInput-root {
     background: #fff;
 }
 
 .miscList .MuiTableHead-root th,
 .miscList .MuiTableCell-body {
     padding: 10px 8px !important;
 }
 
 @media only screen and (max-width: 991.98px) {
     .adminlisttable_v2 tr {
         display: block;
     }
     .adminlisttable_v2 tbody tr td {
         padding: 5px 10px!important;
         display: block;
     }
     .adminlisttable_v2 tr th:nth-of-type(1) {
         padding: 5px 2%!important;
         width: 96%;
     }
 }
 /* ====================USER management listing======================== */
 /* =======================Training Center===================== */
 
 .trainingCenter_wrpr {
     min-height: 100vh;
     background-color: #cbf3fd;
     /* overflow: hidden; */
 }
 
 .trainingCenter_tile {
     text-align: center;
     background: rgb(158, 143, 255);
     background: linear-gradient(180deg, rgba(158, 143, 255, 1) 0%, rgba(114, 97, 229, 1) 100%);
     padding: 15px;
 }
 
 .trainingCenter_tile h1 {
     font-size: 30px;
     font-weight: 700;
     font-family: 'Poppins', sans-serif;
     color: #fff;
 }
 
 .trainingCenter_main {
     margin-left: 0px !important;
     width: 100% !important;
     justify-content: space-between;
     padding: 20px !important;
 }
 
 .traingcenterdata_wrapper {
     /* padding: 15px; */
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     padding-left: 0 !important;
     padding-top: 0px!important;
 }
 
 .traingcenterdata_wrapper app-traing-center {
     /* margin: 0.5%; */
     border-radius: 0px;
     box-shadow: -1px 1px 6px #0000008f;
     width: 100%;
     transition: transform 0.2s;
     background: rgb(66, 201, 255);
     background: linear-gradient(138deg, rgba(66, 201, 255, 1) 0%, rgba(2, 116, 187, 1) 100%);
     padding: 0.6%;
     border: 1px solid #f5f5f5;
 }
 
 .traingcenterdata_wrapper .tncgCenter_dta {
     background-color: #ffffff;
     border-radius: 5px;
 }
 
 .traingcenterdata_wrapper .tncgCenter_dta .media_content_wrapper .media_content {
     overflow: hidden;
     height: auto;
     padding: 8px;
     background: rgb(83, 91, 94);
     background: linear-gradient(138deg, rgb(127 134 137) 0%, rgba(41, 52, 58, 1) 100%);
     width: auto;
 }
 
 .traingcenterdata_wrapper .tncgCenter_dta .media_content_wrapper img {
     display: block;
     width: 100%;
     object-fit: cover;
     object-position: center;
     height: 100%;
 }
 
 .mediacontrol_wrapper .mediacontrol_icon_wrapper span {
     display: flex;
     /* align-items: center; */
 }
 
 .tittle_wrapper,
 .description_wrapper,
 .mad_button_wrapper {
     padding: 1%;
     /* width: 98%; */
 }
 /* .description_wrapper p {
     word-break: break-all;
 } */
 
 .tittle_wrapper {
     background: rgb(5, 41, 88);
     background: linear-gradient(180deg, rgb(5 41 88 / 81%) 0%, rgba(5, 41, 88, 1) 100%);
     display: flex;
     align-items: center;
 }
 
 .incomplete .tittle_wrapper {
     background: rgb(0, 197, 255);
     background: linear-gradient(180deg, rgba(0, 197, 255, 1) 0%, rgba(10, 133, 170, 1) 100%);
 }
 
 .incomplete .tittle_wrapper p {
     color: #fff;
     text-shadow: 1px 1px 5px #221f06;
 }
 
 .complete .tittle_wrapper {
     background: rgb(68, 209, 61);
     background: linear-gradient(180deg, rgba(68, 209, 61, 1) 0%, rgba(25, 148, 52, 1) 100%);
 }
 
 .complete .tittle_wrapper p {
     color: #fff;
     text-shadow: 1px 1px 5px #221f06;
 }
 
 .ongoing .tittle_wrapper {
     background: rgb(236, 224, 80);
     background: linear-gradient(180deg, rgba(236, 224, 80, 1) 0%, rgba(201, 195, 56, 1) 100%);
 }
 
 .ongoing .tittle_wrapper p {
     color: #fff;
     text-shadow: 1px 1px 5px #221f06;
 }
 
 .expan_icon_wrapper {
     color: #fff;
 }
 
 .tittle_wrapper p {
     font-size: 25px;
     font-weight: 500;
     font-family: 'Poppins', sans-serif;
     text-transform: capitalize;
 }
 
 .expan_icon_wrapper .mat-icon {
     fill: currentColor;
     background-repeat: no-repeat;
     background: #fff;
     border-radius: 100%;
     color: #000;
     display: inline-block;
     height: auto;
     margin-left: 10px;
     padding: 2px 1px 0;
     width: auto;
 }
 
 .description_wrapper p {
     font-size: 20px;
     font-weight: 400;
     font-family: 'Poppins', sans-serif;
     text-transform: capitalize;
 }
 
 .traingcenterdata_wrapper .mad_button_wrapper {
     display: flex;
     justify-content: flex-end;
 }
 
 .mad_button_wrapper button {
     background-color: #00c8f5;
     padding: 10px 15px;
     border-radius: 5px;
     font-size: 18px;
     color: #fff;
     font-weight: 600;
     border: none;
     margin: 2px;
 }
 
 .media_content video {
     object-fit: cover;
     height: 100%;
     width: 100%;
 }
 
 .media_content audio {
     width: 100%;
     position: relative;
     top: 50%;
 }
 
 .traingcenterList_wrapper {
     background-color: #00c8f5;
     position: sticky;
     top: 0;
     height: fit-content;
     right: 0;
     /* border: 5px solid #00c8f5; */
     padding-left: 0 !important;
     padding-top: 0px!important;
 }
 
 .traingcenterList_wrapper app-traing-center,
 .trainingCenter_main .tittle_wrapper {
     cursor: pointer;
 }
 
 .trainingCenter_wrpr .show_percentage {
     background-color: #16c7f9;
     margin: 0;
     padding: 26px 0;
 }
 
 .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar {
     display: flex;
     justify-content: space-between;
     align-items: center;
     background-color: #ffffff;
     border-radius: 5px;
     box-shadow: 0px 1px 6px 2px rgb(0 0 0 / 16%);
     padding: 0px 29px;
 }
 
 .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar .totalcountshow {
     width: 35%;
 }
 
 .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar .progressbar_wrapper {
     width: 60%;
     display: flex;
     justify-content: end;
     align-items: center;
     background: rgb(255, 255, 255);
     /* background: linear-gradient(180deg, rgb(5 41 88 / 81%) 0%, rgba(5,41,88,1) 100%); */
     border-radius: 50px;
     padding: 20px 0;
 }
 
 .totalcountshow p {
     font-size: 28px;
     font-weight: 600;
     font-family: 'Poppins', sans-serif;
     text-align: center;
 }
 
 .progressbar_wrapper mat-progress-bar {
     width: 94%;
     height: 30px;
     border-radius: 20px;
 }
 
 .progressbar_wrapper .mat-progress-bar .mat-progress-bar-buffer {
     /* background: rgb(214, 123, 233);
     background: linear-gradient(90deg, rgba(214, 123, 233, 1) 0%, rgba(214, 123, 233, 1) 100%); */
     /* border: 1px solid #fff; */
     background-color: #000;
 }
 
 .progressbar_wrapper .mat-progress-bar-fill::after {
     /* background-color: #5a009e !important; */
     background-color: #1cd744 !important;
 }
 
 .progressbar_wrapper p {
     font-size: 22px;
     font-weight: 600;
     padding-left: 10px;
     white-space: nowrap;
     word-break: normal;
 }
 /* =======================Training Center===================== */
 /*----------------------ADDITIONAL TRAINING------------------*/
 
 .backendTableInline_addform .category {
     flex: 0 0 48%;
 }
 
 .admin_addForm .category .MuiFormControl-root {
     background-color: #fff;
     margin: 0;
     width: 100%;
 }
 
 .admin_addForm .category .MuiInputBase-root fieldset legend span {
     display: none;
 }
 
 .admin_addForm .category .MuiFormControl-root .MuiInputLabel-shrink {
     display: none;
 }
 /*------------------------------------------------------------------*/
 
 .submenu_item:hover {
     background-color: #052958 !important;
     color: rgb(255, 255, 255);
 }
 
 .submenu_item {
     font-size: 16px;
     font-weight: 400;
     color: #ffff;
 }
 
 .submenu_item a {
     color: #000;
 }
 
 .submenu_item a:hover {
     color: #fff;
 }
 
 .myAcnt_modal li a {
     color: #000;
 }
 /* ===========Success Page=========== */
 
 .darktheme {
     min-height: 100vh !important;
     overflow: hidden;
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_calcelbookigslot.jpg), no-repeat;
     background-size: cover;
     position: relative;
     background-repeat: no-repeat;
     align-items: center;
 }
 
 .thankYou_wrpr {
     min-height: 100vh;
 }
 
 .thankYou_wrpr .content {
     /* background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/thankYou_txtbnr1.jpg); */
     background: rgb(183, 236, 250);
     background: linear-gradient(180deg, rgba(183, 236, 250, 1) 0%, rgba(28, 109, 182, 1) 100%);
     background-repeat: no-repeat;
     background-size: cover;
     border-radius: 5px;
     padding: 10px;
     margin: 30px!important;
 }
 
 .thankYou_wrpr button {
     background: #00a5d2;
     color: #0d0c0c;
     font-size: 25px;
     padding: 15px;
     box-shadow: none;
     border: 1px solid #fff;
     border-radius: 5px;
     margin: 15px auto;
 }
 
 .darktheme .content {
     text-align: left;
     position: absolute;
     margin: 0;
     top: 50%;
     left: 50%;
     margin-right: -50%;
     transform: translate(-50%, -50%);
 }
 
 .thankYou_wrpr.darktheme .content {
     box-shadow: 1px 1px 5px 5px rgb(55 78 163);
 }
 
 .darktheme .content h1 {
     /* background: blue;  */
     /* background-color: #052958!important; */
     color: #fff;
     font-size: 32px;
     line-height: 42px;
     font-weight: 700;
     font-family: 'Poppins', sans-serif;
     padding: 15px;
 }
 
 .darktheme .content p {
     padding: 5px 15px;
     font-size: 20px;
 }
 
 .darktheme .content p strong {
     background-color: #ffea00;
     color: transparent;
     -webkit-background-clip: text;
     -moz-background-clip: text;
     font-weight: 700;
 }
 
 .darktheme .content p span {
     color: #ffffff;
 }
 
 .thankYou_wrpr .content h1 {
     color: #000000;
     /* text-shadow: 0 0 2px #000c0e; */
     text-shadow: 0 0 2px #ffff;
 }
 
 .thankYou_wrpr .content p span {
     color: #000000;
     font-weight: 500;
 }
 
 .darktheme .calender_block:hover {
     transform: none;
 }
 
 .modalBaseStyle {
     position: relative !important;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 50%;
     border: 10px solid rgb(78, 85, 89);
     ;
     border-radius: 4px;
 }
 
 .trainingDesc_modal .closeModalBtn {
     background: rgb(78, 85, 89)!important;
 }
 /* ===========Success Page=========== */
 
 .backLink {
     position: fixed;
     z-index: 9;
     right: 0;
     top: 105px;
     padding: 6px 0 0 10px;
     cursor: pointer;
     border-radius: 40px 0 0 40px;
     background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(185, 191, 195, 0.9) 100%);
 }
 
 .backLink svg {
     width: 40px;
     height: 40px;
     color: #2186a1;
 }
 
 .backLinkHome {
     top: 140px;
 }
 /* .calender_body {
     min-height: 75vh;
     width: 100%;
     padding: 15px 0!important;
     display: flex;
     align-items: center;
     justify-content: center;
 } */
 /* .calender_block {
  display:  block;
  width: 800px;
  max-width:80%;
  margin: 15px;
  padding: 15px;
  box-shadow: 0 0px 10px #d7d7d7;
  border-radius: 5px;
  background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_training_BG.webp) no-repeat 0 0;
  background-size: cover;
} */
 
 .calender_block h2 {
     color: #fff600;
     display: block;
     font-size: 28px;
     font-weight: 700;
     line-height: 35px;
     margin: 0 0 15px 0;
     padding: 0;
     text-shadow: 0 0 2px #235a66;
     background-color: #052958;
     padding: 10px;
     border-radius: 5px;
 }
 
 .calender_block p {
     font-weight: 500;
     color: #fff;
     font-size: 22px;
     margin: 2px 0;
     padding: 0;
     text-shadow: 0 0 2px #235a66;
     padding: 0 5px;
 }
 
 .calender_block p strong {
     color: #fff600;
 }
 
 .calender_block_con_body h2 {
     color: #fff600;
     display: block;
     font-size: 25px;
     font-weight: 700;
     line-height: 32px;
     margin: 0 0 15px 0;
     padding: 0;
     text-shadow: 0 0 2px #235a66;
     background: none;
     border-radius: 0;
 }
 
 .calender_block .innerSpan {
     display: inline-block;
 }
 
 .cancelSlotBtnSection {
     display: flex;
     justify-content: center;
     margin-top: 10px;
 }
 
 .cancelSlotBtnSection button {
     background: linear-gradient(180deg, #46555b, #040506);
     border: 1px solid #fff;
     cursor: pointer;
     display: block;
     font-size: 20px;
     font-weight: 500;
     line-height: 30px;
     padding: 8px;
     text-align: center;
     text-decoration: none;
     margin: 5px;
 }
 
 @media only screen and (max-width: 1622px) {
     .celender_Slot .bannerContent h1 {
         font-size: 33px;
     }
 }
 
 @media only screen and (max-width:1450px) {
     .celender_Slot .bannerContent h1 {
         font-size: 30px;
     }
     .celender_Slot .bannerContent h3 {
         font-size: 20px;
     }
     .celender_Slot .trainingCenter_Banner .trainingCenter_BannerSub {
         background-size: 67% 100%;
         background-position: right -52px bottom;
     }
 }
 
 @media only screen and (max-width:1320px) {
     .celender_Slot .bannerContent h1 {
         font-size: 27px;
     }
 }
 
 @media only screen and (max-width: 1199px) {
     /* .calender_block h2 {
         font-size: 28px;
     } */
     .calender_block_con_body h2 {
         font-size: 25px;
     }
     .tittle_wrapper p {
         font-size: 22px;
         line-height: 24px;
     }
     .trainingCenter_Sub {
         display: block;
     }
     .traingcenterdata_wrapper {
         width: auto!important;
     }
     .traingcenterdata_wrapper app-traing-center {
         display: block;
     }
     .commonWidth_admin .MuiGrid-root {
         display: block!important;
     }
     .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar {
         display: block;
     }
     .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar .totalcountshow {
         width: auto;
         padding: 10px 0 0 0;
     }
     .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar .progressbar_wrapper {
         width: auto;
         padding: 5px 0 15px 0;
     }
     .trainingCenter_wrpr .show_percentage .trainingCenter_Progressbar {
         padding: 0 15px;
     }
     .celender_Slot .bannerContent {
         flex-direction: column;
         width: 100%;
         margin: 30px 0;
         padding: 0px;
     }
     .celender_Slot .trainingCenter_Banner .trainingCenter_BannerSub {
         background: none;
     }
     .celender_Slot .calendrbanner_logo {
         width: 40%;
         margin: 20px auto 0;
     }
     .celender_Slot .calendrbanner_logo img {
         /* width: 50%; */
         display: block;
         margin: 0 auto;
     }
     .celender_Slot .bannerContent_txt {
         width: 94%;
         margin-top: 20px;
         padding: 0 20px 20px;
     }
     .celender_Slot .bannerContent h1 {
         font-size: 35px;
     }
 }
 /* @media only screen and (max-width:1057px){
  .celender_Slot .bannerContent{
  width: 100%;
  padding: 0;
}
} */
 
 @media only screen and (max-width: 750px) {
     /* .celender_Slot .bannerContent{
  margin: 20px auto;
  width: 90%;
} */
     .celender_Slot .bannerContent h3 {
         line-height: 25px;
     }
     .celender_Slot .bannerContent h1 {
         font-size: 28px;
         line-height: 30px;
     }
 }
 
 @media only screen and (max-width: 600px) {
     .celender_Slot .bannerContent_txt {
         padding: 0 10px 20px;
         margin: 20px auto 0;
     }
     .celender_Slot .bannerContent h3 {
         font-size: 18px;
     }
     .dltStsModal .MuiBox-root {
         width: 77%;
     }
     .celender_Slot .bannerContent {
         margin: 13px 0;
     }
     .celender_Slot .calendrbanner_logo {
         width: 55%;
     }
     .calcelSlot_box .calender_block h2 {
         font-size: 25px;
         line-height: 30px;
     }
     /* .celender_Slot .calendrbanner_logo{
        display: none;
    } */
 }
 /* ===================Search Field======================== */
 
 .countDiv__column--col4 {
     flex: 1 0 31.5%;
 }
 
 .countDiv__column--col3 {
     flex: 1 0 23%;
 }
 
 .countDiv__column--col2 {
     flex: 1 0 18.5%;
 }
 
 .PatientSearch .searchForm {
     display: flex;
     flex-wrap: wrap;
     padding: 5px;
     align-items: center;
     justify-content: center;
 }
 
 .PatientSearch .createdon_datetime {
     align-items: center;
     display: flex;
     /* font-size: 18px; */
     /* margin-bottom: 0; */
     /* padding: 8px; */
     text-transform: capitalize;
 }
 
 .formGroup {
     margin: 5px;
 }
 
 .formGroup .MuiFormControl-root {
     /* padding: 8px; */
     margin: 0!important;
 }
 
 .PatientSearch .searchForm .createdon_datetime label div {
     display: flex;
     /* justify-content: center; */
     align-items: center;
     /* margin: 15px 0 0 0;  */
     padding: 10px;
     /* background: linear-gradient(180deg, rgba(10, 58, 112, 1) 0%, rgba(3, 28, 56, 1) 100%); */
     background: rgb(0, 185, 254);
     background: linear-gradient(180deg, rgba(0, 185, 254, 1) 0%, rgba(1, 102, 157, 1) 100%);
     font-size: 18px;
     color: #fff;
     height: 36px;
     /* margin-bottom: 10px;  */
     border-radius: 4px 0 0 4px;
 }
 
 .PatientSearch .createdon_datetime .MuiInputLabel-root {
     width: 49%;
 }
 
 .PatientSearch .searchForm .createdon_datetime .MuiFormControl-root {
     margin: 0!important;
     width: 50%!important;
 }
 
 .PatientSearch .createdon_datetime .MuiFormControl-root .MuiInputLabel-root {
     width: 100%;
 }
 
 .PatientSearch .searchForm .createdon_datetime .MuiFormControl-root .MuiDateRangePickerInput-root:nth-child(1) .MuiTextField-root .MuiInputBase-root {
     border-radius: 0 4px 4px 0;
 }
 
 .searchBarHead {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     /* background: rgb(10, 58, 112);
     background: linear-gradient(180deg, rgba(10, 58, 112, 1) 0%, rgba(3, 28, 56, 1) 100%); */
     background: rgb(0, 185, 254);
     background: linear-gradient(180deg, rgba(0, 185, 254, 1) 0%, rgba(1, 102, 157, 1) 100%);
     padding: 10px;
     align-items: center;
 }
 
 .searchBarHeading {
     color: #fff;
 }
 
 .searchBarCloseIcon button {
     margin: 0;
     padding: 0;
     color: #000;
 }
 
 .searchBarCloseIcon {
     background: #fff;
     width: 25px;
     height: 25px;
     padding: 0;
     border-radius: 100px;
     margin: 0px 0 0 0;
     text-align: center;
 }
 
 .PatientSearch .searchForm .searchBtn {
     /* background: rgb(0, 185, 254);
     background: linear-gradient(180deg, rgba(0, 185, 254, 1) 0%, rgba(1, 102, 157, 1) 100%); */
     background: rgb(70, 85, 91);
     background: linear-gradient(180deg, rgba(70, 85, 91, 1) 0%, rgba(3, 3, 3, 1) 100%);
     display: block;
     margin: 0 auto;
     min-width: 150px;
     color: #fff;
     font-size: 20px;
     padding: 10px 16px !important;
 }
 
 .mainloderwrpaer {
     background: linear-gradient(180deg, #25cfe9 27%, #42b3f5 58%);
     display: block;
     padding: 5%;
 }
 
 .subloderwraper {
     overflow: hidden;
     margin: 0 auto;
     width: 600px;
     display: flex;
     justify-content: center;
 }
 
 .loaderlogo img {
     display: block;
     width: auto;
     max-width: 320px;
     object-fit: cover;
     object-position: center;
     height: 100%;
 }
 
 .modalBaseStyle {
     position: relative !important;
     top: 50% !important;
     left: 50% !important;
     transform: translate(-50%, -50%);
     width: 50%;
     border: 10px solid #0e133b !important;
     border-radius: 4px;
 }
 
 .trainingDesc_modal app-traing-center .test_html_class {
     /* background: linear-gradient(184deg,#ffffff 27%,#c0b9b9 67%); */
     background-color: #dbe4eb;
     background-repeat: no-repeat;
     overflow-y: scroll;
     height: 80vh;
 }
 
 .trainingDesc_modal app-traing-center .tittle_wrapper {
     background: linear-gradient(180deg, #25cfe9 27%, #42b3f5 58%);
 }
 
 .trainingDesc_modal app-traing-center .media_content_wrapper .media_content {
     overflow: hidden;
     margin: 0 auto;
     width: 600px;
 }
 
 .trainingDesc_modal app-traing-center .media_content_wrapper .media_content img {
     display: block;
     width: 100%;
     object-fit: cover;
     object-position: center;
     height: 100%;
 }
 
 .trainingDesc_modal app-traing-center .test_html_class .description_wrapper {
     border-top: 10px solid #0e133b;
     background-color: #dbe4eb;
 }
 
 .trainingDesc_modal app-traing-center .expan_icon_wrapper {
     display: none;
 }
 
 .trainingDesc_modal .closeModalBtn {
     background: #0e133b !important;
 }
 
 .userProfileForm .inputBlock .MuiFormControl-root {
     border: 1px solid #ccc;
     background-color: #fff;
 }
 
 .userProfileForm .addressbox .MuiFormControl-root {
     border: none;
     background: none;
 }
 /* .loginMain_wrpr .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    display: none;
  } */
 /* -------- In userMangement training percentage indicators ------ */
 
 .adminlisttable_v2 tbody tr td span.training-red {
     padding: 5px;
     background: #d70101;
     width: 80px;
     display: block;
     text-align: center;
     border-radius: 25px;
     color: white;
     font-weight: bold;
 }
 
 .adminlisttable_v2 tbody tr td span.training-green {
     padding: 5px;
     background: rgb(156, 170, 2);
     width: 80px;
     display: block;
     text-align: center;
     border-radius: 25px;
     color: white;
     font-weight: bold;
 }
 
 .adminlisttable_v2 tbody tr td span.training-yellow {
     padding: 5px;
     background: rgb(227, 171, 16);
     width: 80px;
     display: block;
     text-align: center;
     border-radius: 25px;
     color: white;
     font-weight: bold;
 }
 
 .adminlisttable_v2 tbody tr td span.training-green,
 .adminlisttable_v2 tbody tr td span.training-red,
 .adminlisttable_v2 tbody tr td span.training-yellow {
     border-radius: 25px;
     color: #fff;
     display: block;
     /* margin-top: 21px; */
 }
 
 .login_body .MuiSnackbar-root .MuiSnackbarContent-root {
     font-size: 16px;
     min-height: 55px;
 }
 
 .bookedListWrpr thead tr th {
     padding: 10px 5px !important;
 }
 
 .bookedListWrpr thead tr th:nth-child(11) {
     width: 10%;
 }
 
 .reactTableWrapper .MuiTableBody-root td:nth-child(3),
 .reactTableWrapper .MuiTableBody-root td:nth-child(4),
 .reactTableWrapper .MuiTableBody-root td:nth-child(5) {
     word-break: break-all;
 }
 
 @media only screen and (max-width: 1300px) {
     .subloderwraper {
         width: 100%;
     }
 }
 
 @media only screen and (max-width: 1199px) {
     .calender_block p {
         font-size: 18px;
     }
     .reactTableWrapper thead {
         display: none;
     }
     .reactTableWrapper tbody,
     .reactTableWrapper td,
     .reactTableWrapper tr {
         display: block!important;
     }
     .reactTableWrapper tr {
         border: 1px solid #b8c4d3!important;
     }
     .reactTableWrapper .MuiTableBody-root td,
     .reactTableWrapper .MuiTableHead-root th {
         padding: 1%!important;
         width: 98%!important;
     }
     .reactTableWrapper .MuiTableBody-root td:first-child {
         text-align: left!important;
     }
     .reactTableWrapper td:before {
         content: attr(title);
         float: left;
         font-weight: 700;
         padding-right: 10px;
         text-transform: uppercase;
     }
     .reactTableWrapper .MuiTableBody-root td {
         text-align: right!important;
     }
     /* .adminlisttable_v2 tbody tr td span.training-red, .adminlisttable_v2 tbody tr td span.training-yellow, .adminlisttable_v2 tbody tr td span.training-green{
  margin: 0 0 0 auto;
} */
     .reactTableWrapper .user_Managemnt .MuiTableBody-root td:nth-child(2) {
         display: none !important;
     }
     .reactTableWrapper .MuiTableBody-root td button {
         padding: 6px;
     }
     .admin_page #tableTitle {
         font-size: 21px;
     }
     .reactTableWrapper table {
         min-width: unset;
     }
     .reactTableWrapper .MuiTableBody-root td:first-child .MuiCheckbox-root {
         padding: 0;
     }
     .reactTableWrapper .conflictingSlotLists tbody td:nth-child(1),
     .reactTableWrapper .conflictingFulldayEventLists tbody td:nth-child(1) {
         text-align: right !important;
     }
     .trainingCenter_Sub {
         display: flex;
         flex-direction: column-reverse;
     }
     .commonWidth_admin .MuiGrid-root {
         display: flex !important;
         width: 100% !important;
     }
     .PatientSearch .formGroup {
         flex: 1 0 48%!important;
     }
     .modalBaseStyle {
         width: 80%;
     }
 }
 /* @media only screen and (max-width: 1199px) {
     font-weight: 700;
     padding: 5px;
     text-align: center;
     width: 80px;
     background: #d70101;
 }
 
 .adminlisttable_v2 tbody tr td span.training-green {
     border-radius: 25px;
     color: #fff;
     display: block;
     font-weight: 700;
     padding: 5px;
     text-align: center;
     width: 80px;
     background: #9caa02;
 }
 
 .adminlisttable_v2 tbody tr td span.training-yellow {
     border-radius: 25px;
     color: #fff;
     display: block;
     font-weight: 700;
     padding: 5px;
     text-align: center;
     width: 80px;
     background: #c9ab11;
 }
 
 .admin_addForm thead .MuiTableSortLabel-icon {
     opacity: 1!important;
 }
 /* /-----------table css start by subhashis............../ */
 
 .trainingCenter_main {
     margin-left: 0px !important;
     width: 100% !important;
     justify-content: space-between;
     padding: 0px !important;
     margin-top: 0 !important;
 }
 
 .commonWidth_admin {
     width: 1764px;
     margin: 0 auto;
 }
 
 .trainingCenter_Sub {
     /* margin-left: 0px !important; */
     width: 100% !important;
     justify-content: space-between;
     /* padding: 20px !important; */
     display: flex;
     margin: 20px 0 !important;
 }
 
 .trainingCenter_Sub .MuiGrid-grid-xl-8 {
     width: 70%!important;
     max-width: inherit!important;
     flex-basis: inherit!important;
 }
 
 .commonWidth_admin .MuiGrid-root {
     margin-left: auto !important;
     margin-right: auto !important;
     margin-top: 0 !important;
 }
 
 .traingcenterdata_wrapper {
     /* padding: 15px; */
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     padding-left: 0 !important;
     padding-top: 0px !important;
 }
 
 .traingcenterdata_wrapper app-traing-center {
     /* margin: 0.5%; */
     border-radius: 0px;
     box-shadow: -1px 1px 6px #0000008f;
     width: 100%;
     transition: transform 0.2s;
     background: rgb(28 25 127);
     background: linear-gradient(138deg, rgb(28 24 126) 0%, rgb(9 39 72) 100%);
     padding: 0;
     border: 1px solid #f5f5f5;
 }
 
 .traingcenterdata_wrapper .tncgCenter_dta {
     background-color: #ffffff;
     border-radius: 0px;
 }
 
 .traingcenterdata_wrapper app-traing-center .tncgCenter_dta {
     margin: 5px;
 }
 
 .tittle_wrapper {
     background: linear-gradient(180deg, #25cfe9 27%, #42b3f5 58%);
     display: flex;
     align-items: center;
 }
 
 .ongoingg .tittle_wrapper p {
     color: #fff;
     text-shadow: 1px 1px 5px #221f06;
 }
 
 .ongoing .tittle_wrapper {
     background: rgb(236, 224, 80);
     background: linear-gradient( 180deg, rgba(236, 224, 80, 1) 0%, rgba(201, 195, 56, 1) 100%);
 }
 
 .traingcenterList_wrapper app-traing-center,
 .trainingCenter_main .tittle_wrapper {
     cursor: pointer;
 }
 
 .trainingDesc_modal .test_html_class {
     /* {background: linear-gradient(184deg,#ffffff 27%,#c0b9b9 67%); */
     background-color: #dbe4eb;
     background-repeat: no-repeat;
 }
 /* overflow-y: scroll;} */
 /* height: 80vh;} */
 
 .tittle_wrapper,
 .description_wrapper,
 .mad_button_wrapper {
     padding: 1%;
     /* width: 98%; */
 }
 
 .traingcenterdata_wrapper .tncgCenter_dta .media_content_wrapper .media_content {
     overflow: hidden;
     height: auto;
     padding: 8px;
     background: rgb(83, 91, 94);
     background: linear-gradient( 138deg, rgb(127 134 137) 0%, rgba(41, 52, 58, 1) 100%);
     width: auto;
     position: relative;
 }
 
 .media_content video {
     object-fit: cover;
     height: 100%;
     width: 100%;
 }
 
 .media_content[_ngcontent-umg-c107] video[_ngcontent-umg-c107] {
     width: 100%;
 }
 
 .mediacontrol_wrapper[_ngcontent-umg-c107] {
     padding: 0 15px;
 }
 
 .traingcenterdata_wrapper .mediacontrol_wrapper {
     padding: 0 !important;
     margin: 0;
 }
 
 .mediacontrol_icon_wrapper[_ngcontent-umg-c107] {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }
 
 .traingcenterdata_wrapper .mediacontrol_icon_wrapper {
     background: #fff;
     padding: 2px;
 }
 
 .mediacontrol_wrapper .mediacontrol_icon_wrapper span {
     display: flex;
     /* align-items: center; */
 }
 
 .mediacontrol_wrapper .mediacontrol_icon_wrapper span {
     display: flex;
     /* align-items: center; */
 }
 
 .traingcenterdata_wrapper .mat-progress-bar-fill::after {
     background-color: #fcd602;
 }
 
 .tittle_wrapper,
 .description_wrapper,
 .mad_button_wrapper {
     padding: 15px;
     /* padding: 15px 15px 0; */
     /* width: 98%; */
 }
 
 .traingcenterdata_wrapper .mad_button_wrapper {
     display: flex;
     justify-content: flex-end;
     padding-top: 0;
 }
 /* .description_wrapper {padding-bottom: 5px;} */
 
 .description_wrapper p {
     font-size: 18px;
     line-height: 22px;
     font-weight: 400;
     font-family: "Poppins", sans-serif;
     text-transform: capitalize;
 }
 
 .mad_button_wrapper button {
     background-color: #00c8f5;
     padding: 10px 15px;
     border-radius: 5px;
     font-size: 18px;
     color: #fff;
     font-weight: 600;
     border: none;
     margin: 2px;
     cursor: pointer;
 }
 
 .traingcenterdata_wrapper app-traing-center {
     /* margin: 0.5%; */
     border-radius: 0px;
     box-shadow: -1px 1px 6px #0000008f;
     width: 100%;
     transition: transform 0.2s;
     background: rgb(28 25 127);
     background: linear-gradient(138deg, rgb(28 24 126) 0%, rgb(9 39 72) 100%);
     padding: 0;
     border: 1px solid #f5f5f5;
 }
 
 .PatientSearch .joindate .MuiFormControl-root {
     width: 48.5%;
 }
 /* /-----------table css end by subhashis............../ */
 
 .adminformbody .formInline .state .MuiFormControl-root {
     background-color: #fff;
     width: 100%;
     margin-bottom: 0;
     border: solid 1px #ccc;
     height: 48px;
 }
 
 .adminformbody .formInline .state .MuiFormControl-root .MuiInputLabel-root {
     display: block;
 }
 
 .login_body .MuiSnackbar-root .MuiSnackbarContent-root {
     font-size: 16px;
     min-height: 55px;
 }
 /* ============new delete & preview modal=============== */
 
 .notes_details .MuiPaper-root {
     border: 10px solid #0785d1!important;
     background-color: #c8c8c8;
     padding: 15px!important;
     position: relative!important;
     border-radius: 10px;
 }
 
 .notes_details .close_icon_btn {
     width: 20px!important;
     height: 20px!important;
     background: #0785d1!important;
     color: #fff!important;
     border-radius: 100%!important;
     right: -20px!important;
     position: absolute!important;
     top: -20px!important;
     min-width: inherit!important;
     padding: 15px!important;
 }
 
 @media only screen and (max-width: 1199px) {
     .calender_block p {
         font-size: 18px;
     }
     .reactTableWrapper thead {
         display: none;
     }
     .reactTableWrapper tbody,
     .reactTableWrapper td,
     .reactTableWrapper tr {
         display: block!important;
     }
     .reactTableWrapper tr {
         border: 1px solid #b8c4d3!important;
         border-bottom: 4px solid rgb(78, 85, 89)!important;
     }
     .reactTableWrapper .MuiTableBody-root td,
     .reactTableWrapper .MuiTableHead-root th {
         padding: 1% 2%!important;
         width: 96%!important;
     }
     .reactTableWrapper .MuiTableBody-root td {
         width: auto !important;
     }
     .reactTableWrapper .MuiTableBody-root td:first-child {
         text-align: left!important;
     }
     .reactTableWrapper td:before {
         content: attr(title);
         /* float: left; */
         font-weight: 700;
         padding-right: 10px;
         text-transform: uppercase;
         text-align: left;
         position: absolute;
         left: 8px;
         width: 50%;
     }
     .reactTableWrapper .MuiTableBody-root td {
         text-align: left!important;
         /* display: flex !important; */
         justify-content: space-between;
         /* align-items: center; */
         word-break: break-all;
         padding-left: 50% !important;
         position: relative;
     }
     .userListingMain .reactTableWrapper .MuiTableBody-root td:nth-child(4) {
         align-items: center;
     }
     .reactTableWrapper .MuiTableBody-root td:first-child {
         padding-left: 6px !important;
     }
     .reactTableWrapper .MuiTableBody-root td:last-child {
         display: block !important;
         text-align: left !important;
         /* padding-left: 0 !important; */
     }
     .miscList .MuiTableBody-root td:last-child {
         text-align: left !important;
     }
     /* .adminlisttable_v2 tbody tr td span.training-red {
         margin: 0 0 0 auto;
     } */
     .reactTableWrapper .user_Managemnt .MuiTableBody-root td:nth-child(2),
     .reactTableWrapper .training_List .MuiTableBody-root td:nth-child(2) {
         display: none !important;
     }
     .reactTableWrapper .MuiTableBody-root td button {
         padding: 6px;
     }
     .admin_page #tableTitle {
         font-size: 21px;
     }
     .reactTableWrapper table {
         min-width: unset;
     }
     .reactTableWrapper .MuiTableBody-root td:first-child .MuiCheckbox-root {
         padding: 0;
     }
     .reactTableWrapper .conflictingSlotLists tbody td:nth-child(1),
     .reactTableWrapper .conflictingFulldayEventLists tbody td:nth-child(1) {
         /* text-align: right !important; */
         text-align: left !important;
     }
     .trainingCenter_Sub {
         display: flex;
         flex-direction: column-reverse;
     }
     .commonWidth_admin .MuiGrid-root {
         display: flex !important;
         width: 100% !important;
     }
     .PatientSearch .formGroup {
         flex: 1 0 48%!important;
     }
     .modalBaseStyle {
         width: 80%;
     }
     .reactTableWrapper tr td:nth-child(even) {
         background-color: rgba(0, 0, 0, 0.04);
     }
     .css-1q1u3t4-MuiTableRow-root.MuiTableRow-hover:hover {
         background-color: rgba(0, 0, 0, 0.04);
     }
     .thankYou_wrpr .content {
         display: block;
         margin: 0 auto !important;
         width: 70%;
     }
 }
 /* @media only screen and (max-width: 1199px) {
  .PatientSearch .createdon_datetime {
    flex-direction: column;
}
.PatientSearch .formGroup {
  flex: 0 0 99%!important;
}
} */
 
 @media only screen and (max-width: 991px) {
     .PatientSearch .searchForm .createdon_datetime label div,
     .PatientSearch .searchForm .createdon_datetime .MuiFormControl-root .MuiDateRangePickerInput-root:nth-child(1) .MuiTextField-root .MuiInputBase-root {
         border-radius: 0;
     }
     .PatientSearch .createdon_datetime .MuiInputLabel-root {
         width: 100%;
         margin-bottom: 6px;
         font-size: 16px;
     }
     .PatientSearch .searchForm .createdon_datetime .MuiFormControl-root {
         width: 100% !important;
     }
     .PatientSearch .createdon_datetime {
         flex-direction: column;
     }
     .PatientSearch .formGroup {
         flex: 0 0 99%!important;
     }
     /* .darktheme .content h1{
    line-height: 55px;
} */
     .thankYou_wrpr .content {
         display: block;
         margin: 40px auto;
         position: relative;
         top: 0;
         left: 0;
         transform: translate(0, 0);
     }
     .thankYou_wrpr {
         display: flex;
         align-items: center;
         justify-content: center;
     }
     .subloderwraper img {
         width: 100%;
     }
     .reactTableWrapper .MuiTableBody-root td {
         width: auto!important;
         padding-left: 50% !important;
     }
 }
 
 @media only screen and (max-width: 650px) {
     .reactTableWrapper .MuiTableBody-root td:last-child {
         padding-left: 0% !important;
     }
     .miscList .MuiTableBody-root td:last-child {
         padding-left: 50% !important;
     }
 }
 
 @media only screen and (max-width: 600px) {
     .darktheme .content h1 {
         font-size: 30px;
         line-height: 40px;
     }
     .upload_data_wrpr #list h1 {
         font-size: 25px;
     }
     .admin_addForm .MuiFab-root {
         height: 40px;
         width: 40px;
     }
     .subloderwraper {
         width: 100%;
     }
     .notes_details .MuiPaper-root {
         padding: 0 !important;
     }
     .notes_details .notes_header {
         text-align: center;
     }
     .notes_details .notes_header p {
         font-size: 19px;
         line-height: 24px;
     }
 }
 
 @media only screen and (max-width: 480px) {
     .commonWidth_admin .MuiGrid-root {
         display: block !important;
     }
     /* .modalblock .MuiBox-root{
  width: 75%;
} */
     .darktheme .content h1 {
         font-size: 22px;
         line-height: 28px;
     }
     .darktheme .content p {
         font-size: 18px;
         line-height: 25px;
     }
     .darktheme .innerSpan {
         word-break: break-all;
     }
     .darktheme .calender_block p {
         font-size: 18px;
     }
     .PatientSearch .searchBarHeading {
         font-size: 16px;
     }
     .reactTableWrapper .MuiTableBody-root td {
         position: relative;
         flex-direction: column;
         text-align: left !important;
         width: auto !important;
         padding: 10px !important;
         padding-left: 8px!important;
     }
     .reactTableWrapper td:before {
         width: 100%;
         display: flex;
         justify-content: flex-start;
         flex-direction: column;
         position: relative;
         left: 0;
     }
     .adminlisttable_v2 tbody tr td span.training-green,
     .adminlisttable_v2 tbody tr td span.training-red,
     .adminlisttable_v2 tbody tr td span.training-yellow {
         margin-top: 21px;
     }
     .miscList .MuiTableBody-root td:last-child {
         padding-left: 8px !important;
     }
 }
 /* #passwordUpdateForm .formGroup .MuiFormControl-root .MuiFormHelperText-contained{
  background: #1684e5;
    color: white;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 19px;
} */
 
 .MuiLinearProgress-root {
     height: 5px !important;
     background: green !important;
     margin: 5px 0 !important;
 }
 
 @media only screen and (max-width: 991px) {
     .PatientSearch .searchForm .createdon_datetime label div,
     .PatientSearch .searchForm .createdon_datetime .MuiFormControl-root .MuiDateRangePickerInput-root:nth-child(1) .MuiTextField-root .MuiInputBase-root {
         border-radius: 0;
     }
     .PatientSearch .createdon_datetime .MuiInputLabel-root {
         width: 100%;
         margin-bottom: 6px;
         font-size: 16px;
     }
     .PatientSearch .searchForm .createdon_datetime .MuiFormControl-root {
         width: 100% !important;
     }
     .PatientSearch .createdon_datetime {
         flex-direction: column;
     }
     .PatientSearch .formGroup {
         flex: 0 0 99%!important;
     }
     /* .darktheme .content h1 {
         line-height: 55px;
     } */
     .thankYou_wrpr .content {
         display: block;
         margin: 40px 20px;
         position: relative;
         top: 0;
         left: 0;
         transform: translate(0, 0);
         padding: 0;
     }
     .thankYou_wrpr {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }
 
 @media only screen and (max-width: 600px) {
     .darktheme .content h1 {
         font-size: 30px;
         line-height: 40px;
     }
     .thankYou_wrpr .content {
         width: 85%;
     }
 }
 
 @media only screen and (max-width: 480px) {
     .commonWidth_admin .MuiGrid-root {
         display: block !important;
     }
     /* .modalblock .MuiBox-root {
         width: 75%;
     } */
     .darktheme .content h1 {
         font-size: 22px;
         line-height: 28px;
     }
     .darktheme .content p {
         font-size: 18px;
         line-height: 25px;
         font-weight: 500;
     }
     .darktheme .innerSpan {
         word-break: break-all;
     }
     .darktheme .calender_block p {
         font-size: 18px;
     }
     .thankYou_wrpr .content h1 {
         font-size: 20px;
         line-height: 26px;
     }
 }
 /* #passwordUpdateForm .formGroup .MuiFormControl-root .MuiFormHelperText-contained {
     background: #d32f2f;
     color: white;
     width: 100%;
     padding: 0;
     margin: 0;
     text-align: center;
     font-size: 19px;
 } */
 
 .MuiLinearProgress-root {
     height: 5px !important;
     background: green !important;
     margin: 5px 0 !important;
 }
 
 .training_loaderTop {
     top: 0;
     position: fixed;
     z-index: 9999;
 }
 
 .training_loaderTop .mat-progress-bar {
     height: 5px;
 }
 
 .training_dropDwn ul,
 .myAcnt_modal ul,
 .addVid_dropDwn ul {
     padding: 0;
 }
 /* .userList_modal .MuiBox-root{
width: 500px;
} */
 
 .userList_modal .ModalBox_scroll .heading {
     background: #052958!important;
     color: #0bb8ff;
     padding: 10px 0;
 }
 
 .userList_modal .ModalBox_scroll .heading h2 {
     font-weight: 500;
 }
 
 .userList_modal .ModalBox_scroll .listflexblock li {
     display: flex;
     justify-content: flex-start;
     word-break: break-word;
     padding: 5px;
 }
 
 .userList_modal .ModalBox_scroll .listflexblock li strong,
 .userList_modal .ModalBox_scroll .listflexblock li span {
     width: 50%;
     text-align: left;
 }
 
 .userList_modal .MuiBox-root ul li:nth-of-type(odd) {
     background: rgba(0, 0, 0, 0.04);
 }
 
 .userList_modal .reactTableWrapper thead {
     display: none;
 }
 
 .userList_modal .MuiBox-root {
     background-color: #ffffff;
     padding: 0 15px!important;
 }
 
 .availibltyPrvw .MuiBox-root {
     width: 550px;
 }
 
 .userList_modal .ModalBox_scroll {
     max-height: unset;
     overflow: unset;
     -webkit-overflow-scrolling: unset;
     overflow-x: unset;
 }
 
 .userList_modal .listflexblock {
     max-height: 65vh;
     overflow: auto;
     -webkit-overflow-scrolling: touch;
     overflow-x: hidden;
 }
 
 .lastLogin_Info .BoxMui_modal {
     width: 500px;
 }
 
 .lastLogin_Info .reactTableWrapper tbody,
 .lastLogin_Info .reactTableWrapper td,
 .lastLogin_Info .reactTableWrapper tr {
     display: block!important;
 }
 
 .lastLogin_Info .adminlisttable_v2 thead {
     display: none !important;
 }
 
 .lastLogin_Info .adminlisttable_v2 tbody {
     overflow-y: scroll;
     height: 33vh;
 }
 
 .lastLogin_Info .reactTableWrapper tr {
     border: 1px solid #b8c4d3!important;
     border-bottom: 4px solid rgb(78, 85, 89)!important;
 }
 
 .lastLogin_Info .reactTableWrapper .MuiTableBody-root td,
 .lastLogin_Info .reactTableWrapper .MuiTableHead-root th {
     padding: 1% 2%!important;
     width: 96%!important;
 }
 
 .lastLogin_Info .reactTableWrapper .MuiTableBody-root td:first-child {
     text-align: left!important;
 }
 
 .lastLogin_Info .reactTableWrapper td:before {
     content: attr(title);
     float: left;
     font-weight: 700;
     padding-right: 10px;
     text-transform: uppercase;
 }
 
 .lastLogin_Info .reactTableWrapper .MuiTableBody-root td {
     text-align: right!important;
 }
 
 .lastLogin_Info .adminlisttable_v2 tbody tr td span.training-red {
     margin: 0 0 0 auto;
 }
 
 .lastLogin_Info .reactTableWrapper .user_Managemnt .MuiTableBody-root td:nth-child(2) {
     display: none !important;
 }
 
 .lastLogin_Info .reactTableWrapper .MuiTableBody-root td button {
     padding: 6px;
 }
 
 .lastLogin_Info .admin_page #tableTitle {
     font-size: 21px;
 }
 
 .lastLogin_Info .reactTableWrapper table {
     min-width: unset;
 }
 
 .lastLogin_Info .reactTableWrapper .MuiTableBody-root td:first-child .MuiCheckbox-root {
     padding: 0;
 }
 
 .lastLogin_Info .reactTableWrapper .conflictingSlotLists tbody td:nth-child(1),
 .lastLogin_Info .reactTableWrapper .conflictingFulldayEventLists tbody td:nth-child(1) {
     text-align: right !important;
 }
 
 .lastLogin_Info .trainingCenter_Sub {
     display: flex;
     flex-direction: column-reverse;
 }
 
 .lastLogin_Info .commonWidth_admin .MuiGrid-root {
     display: flex !important;
     width: 100% !important;
 }
 
 .lastLogin_Info .PatientSearch .formGroup {
     flex: 1 0 48%!important;
 }
 
 .lastLogin_Info .modalBaseStyle {
     width: 80%;
 }
 
 .lastLogin_Info .reactTableWrapper tr td:nth-child(even) {
     background-color: rgba(0, 0, 0, 0.04);
 }
 
 .lastLogin_Info .css-1q1u3t4-MuiTableRow-root.MuiTableRow-hover:hover {
     background-color: rgba(0, 0, 0, 0.04);
 }
 
 .lastLogin_Info .stackPagination .listCount,
 .lastLogin_Info .stackPagination .MuiPagination-root {
     margin: 5px auto 0 !important;
 }
 
 .lastLogin_Info .MuiBox-root ul li {
     background: none !important;
 }
 
 .lastLogin_Info .BoxMui_modal {
     padding: 0!important;
 }
 
 .lastLogin_Info .MuiBox-root {
     padding: 0 !important;
 }
 
 @media only screen and (max-width: 768px) {
     .userList_modal .MuiBox-root {
         width: 70%;
     }
     .trainingDesc_modal app-traing-center .media_content_wrapper .media_content {
         width: 80%;
     }
 }
 
 @media only screen and (max-width: 640.98px) {
     .Dashbaord_TopPH {
         width: auto;
         display: flex;
         align-items: center;
         justify-content: center;
         /* margin-top: 20px; */
     }
     .Dashbaord_TopPH span {
         width: 40px;
         height: 40px;
     }
     .Dashbaord_TopPH span svg {
         width: 28px;
         height: 28px;
     }
 }
 
 @media only screen and (max-width: 600px) {
     .lastLogin_Info .BoxMui_modal {
         width: 82%;
     }
     .contact_block1 app-progressbar .progressbar_component {
         width: 175px;
         margin: 5px auto 0;
     }
 }
 
 .MuiTableSortLabel-icon {
     color: #fff !important;
 }
 
 .Dashbaord_trainingCon3 h1 {
     cursor: pointer;
 }
 
 .Dashbaord_TopPH {
     display: flex;
     align-items: center;
     justify-content: flex-end;
     margin-right: 10px;
 }
 
 .Dashbaord_TopPH a {
     display: flex;
     align-items: center;
     justify-content: flex-end;
     font-size: 25px;
     color: #23bae2;
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
     text-decoration: none;
     text-shadow: 0 1px #0b6a84;
 }
 
 .Dashbaord_TopPH span {
     margin-right: 18px;
     color: #23bde3;
     border: solid 2px #23bde3;
     border-radius: 100%;
     width: 33px;
     height: 33px;
     display: flex;
     align-items: center;
     justify-content: center;
 }
 
 .Dashbaord_TopPH span svg {
     width: 23px;
     height: 23px;
 }
 
 @media only screen and (max-width: 480px) {
     .lastLogin_Info .reactTableWrapper .MuiTableBody-root td {
         text-align: left!important;
     }
 }
 
 @media only screen and (max-width: 450.98px) {
     .Dashbaord_TopPH a {
         font-size: 20px;
     }
     .Dashbaord_TopPH span {
         width: 30px;
         height: 30px;
     }
     .Dashbaord_TopPH span svg {
         width: 20px;
         height: 20px;
     }
 }
 
 .html5-video-player {
     display: none !important;
 }
 
 .tC_newBanner_main {
     /* position: sticky; */
     margin: 0 auto;
     width: 94%;
 }
 
 .tC_newBanner_sub1 {
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_tc_newBlckBG.webp);
     background-size: 100% 100%;
     background-repeat: no-repeat;
     margin-top: 30px;
 }
 
 .tC_newBanner_sub1 .tC_newBnr_bgWrp {
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_tc_nwhmn.png);
     background-size: 66% 100%;
     background-repeat: no-repeat;
 }
 
 .tC_newBanner_sub1 .tC_Mainblock {
     padding: 23px 37px 26px;
     display: flex;
     flex-direction: column;
     align-items: center;
     position: relative;
 }
 
 .tC_newBanner_sub1 .tC_pdf_button {
     position: relative;
     display: block;
     margin: 0 0 0 auto;
     background: rgb(71, 86, 92);
     background: linear-gradient(180deg, rgba(71, 86, 92, 1) 0%, rgba(2, 2, 2, 1) 100%);
     /* margin-top: 38px; */
     font-size: 20px;
     color: #deff00;
     font-weight: 500;
     border: 1px solid #bac1c4;
     text-align: center;
     padding: 5px 18px !important;
     border-radius: 0;
     z-index: 9;
     border: 1px solid #fff;
     margin-bottom: 8px;
     box-shadow: 1px 1px 5px #000;
 }
 
 .tC_newBanner_sub1 .round_Img {
     position: absolute;
     right: 26%;
     bottom: 10%;
     transform: translate(0%, 4%);
 }
 
 .tC_newBanner_sub1 .tC_newBnr_txt {
     padding-left: 45%;
     margin-bottom: 87px;
 }
 
 .tC_newBanner_sub1 .tC_newBnr_txt h1 {
     font-size: 32px;
     font-weight: 800;
     color: #fff;
     text-shadow: 0px 1px 3px rgb(0 0 0);
     font-family: 'Roboto', sans-serif !important;
     text-align: end;
 }
 
 .tC_newBanner_sub1 .tC_newBnr_txt h1 span {
     font-size: 47px;
     font-weight: 800;
     color: #583f07;
     text-shadow: none;
 }
 
 .trainingCenter_rightMain {
     position: sticky;
     top: 0;
     width: 100%;
 }
 
 .tC_newBanner_sub2 {
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_tc_nw2_bg.webp);
     background-size: 100% 100%;
     background-repeat: no-repeat;
     margin-top: 30px;
 }
 
 .tC_newBanner_sub2 .tC_newBnr_bgWrp {
     background: url(https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_tc_nwinr.png);
     background-repeat: no-repeat;
     background-size: 100% auto;
     background-position: left bottom;
 }
 
 .tC_newBanner_sub2 .tC_newBnr_txt h1 {
     font-size: 36px;
     line-height: 38px;
     font-weight: 600;
     text-transform: uppercase;
     color: #fff;
 }
 
 .tC_newBanner_sub2 .tC_Mainblock {
     padding: 29px 29px 31px;
 }
 
 .tC_newBanner_sub2 .tC_pdf_button {
     position: relative;
     display: block;
     /* margin: 0 0 0 auto; */
     background: rgb(71, 86, 92);
     background: linear-gradient(180deg, rgba(71, 86, 92, 1) 0%, rgba(2, 2, 2, 1) 100%);
     margin-top: 138px;
     font-size: 20px;
     color: #deff00;
     font-weight: 500;
     border: 1px solid #ffffff;
     text-align: center;
     padding: 5px 25px !important;
     border-radius: 0;
     z-index: 9;
     box-shadow: 1px 1px 5px #000;
     /* margin-bottom: 8px */
 }
 
 .tC_newBanner_sub2 .round_Img {
     position: absolute;
     bottom: 35px;
     right: 23%;
     transform: translate(3%, 13px);
 }
 
 @media only screen and (max-width: 1800px) {
     .tC_newBanner_sub1 .tC_newBnr_txt h1 {
         font-size: 26px;
     }
     .tC_newBanner_sub1 .tC_newBnr_txt h1 span {
         font-size: 40px;
     }
     .tC_newBanner_sub2 .tC_newBnr_txt h1 {
         font-size: 31px;
     }
     .tC_newBanner_sub2 .round_Img {
         right: 18%;
     }
 }
 
 @media only screen and (max-width: 1600px) {
     .tC_newBanner_sub2 .round_Img {
         right: 13%;
     }
     .tC_newBanner_sub2 .tC_newBnr_txt h1 {
         font-size: 30px;
     }
 }
 
 @media only screen and (max-width: 1540px) {
     .tC_newBanner_sub2 .tC_pdf_button {
         margin-top: 120px;
     }
 }
 
 @media only screen and (max-width: 1199px) {
     .tC_newBanner_main {
         display: none;
     }
 }
 /* .tC_newBanner_main .tC_pdf_button{
    position: relative;
} */
 
 .details_block {
     display: flex;
     flex-wrap: wrap;
 }
 .full_width .MuiFormControl-root {width: 100% !important;}
 .full_width .cursive_class, .full_width .error {margin-left: 20px;}
 .full_width {width: 60%;}
 .col_flex {
     display: flex;
     flex-direction: column;
 }
 
 .col_inline .MuiOutlinedInput-notchedOutline {
     border: none;
     border-bottom: 3px solid #000;
     border-radius: 0;
 }
 
 .error_position {
     display: inline;
     position: relative;
 }
 
 .error_position .error {
     position: absolute;
     bottom: -20px;
     left: 25px;
     font-size: 16px;
 }
 
 .error,
 .error_position .error span {
     color: red;
     font-weight: 400;
 }
 
 .cursive_class {
     margin-bottom: 5px;
     font-family: Parisienne, cursive !important;
 }
 
 .flex_cont {
     display: flex;
     align-items: flex-end;
     width: 100%;
 }
 
 .flex_cont .MuiFormControl-root {
     margin-left: 20px;
 }
 
 .flex_cont .MuiOutlinedInput-notchedOutline {
     border: none;
     border-bottom: 3px solid #000;
 }
 
 .flex_cont .MuiOutlinedInput-root {
     border-radius: 0;
 }
 
 .minimum_height_wrp {
     align-items: flex-end;
     flex-direction: column;
     justify-content: flex-end;
 }
 
 .minimum_height_wrp .Formsubmit_button {
     width: 100%;
     margin-top: 20px;
 }
 
 .minimum_height_wrp button {
     border: none;
     outline: none;
     width: 250px;
     padding: 10px !important;
     font-size: 35px;
     font-weight: 600;
     background: #04044c !important;
     color: #fff;
     display: block;
     margin: 0 auto !important;
     margin-top: 30px !important;
 }