.MuiTableRow-root {
  position: relative;
}

.row_loader {
  position: absolute !important;
  left: 0;
  width: 100%;
}

.notes_details .MuiPaper-root {
  overflow-y: inherit;
}
.view_details .MuiPaper-root {
  overflow-y: inherit;
}

.notes_details .close_icon_btn {
  border-radius: 50% !important;
  height: 30px;
  opacity: 1;
  padding: 5px !important;
  position: absolute !important;
  right: -14px;
  top: -17px;
  width: 30px;
  background-color: #fff;
}
.view_details .close_icon_btn {
  border-radius: 50% !important;
  height: 30px;
  opacity: 1;
  padding: 5px !important;
  position: absolute !important;
  right: -14px;
  top: -17px;
  width: 30px;
  background-color: #fff;
}
.notes_details .notes_header {
  display: flex;
  justify-content: center;
}

.notes_details .hide_table {
  display: none;
}

.view_details .data_container {
  width: fit-content;
}

.view_details .data_block {
  margin: 10px;
}

.view_details .property_container {
  margin-bottom: 10px;
}
.view_details .property_container:nth-child(odd) {background: #d7d7d74f;}

.list_search {
  margin-bottom: 10px;
  padding: 10px;
}

.list_search .formCls .formInline {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.list_search .form_item div {
  display: flex;
  margin: 0;
}

.list_search .form_item {
  flex: 1 0 31.33333333%;
}

.list_search .submitbtnsection {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 15px 0;
  width: 100%;
  align-items: center;
}
