.bottom_block{background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/bottom_footer_bg.webp);background-size: 100% 100%;background-repeat: no-repeat;background-position: left top; padding: 30px 0; position: relative;}
.bottom_block::after{position: absolute; background-color: #055e97;height: 70%; width: 12px; top: 50%; transform:translateY(-50%);left: 0; content: " ";}
.bottom_block::before{position: absolute; background-color: #055e97;height: 70%; width: 12px; top: 50%; transform:translateY(-50%);right: 0; content: " ";}
.bottom_block img {position: absolute;right: 215px; bottom: 0;}
.bottom_innr_block {padding-right:630px; padding-left: 100px;} 
.bottom_innr_block h1{font-size: 68px; color: #fff; line-height: 80px;font-weight: 600; margin-bottom:8px; text-shadow: 2px 2px 3px rgb(61 145 185 / 30%), -1px -1px #37c4e7;
    -webkit-background-clip: text;}
.bottom_block .click_here_sec {display: flex;justify-content: space-between; align-items: center;}
.bottom_block .click_here_sec h3,.bottom_block .click_here_sec h4{color: #fff;font-size: 30px; line-height: 36px;font-weight: 600;text-shadow: 2px 2px 3px rgb(61 145 185 / 30%), 0px 0px #000;
    -webkit-background-clip: text;}
.bottom_block .click_here_sec h3 {    background: linear-gradient(180deg, rgba(219,253,0,1) 0%, rgba(138,200,5,1) 80%);text-align: center;
     width: 28%; text-transform: uppercase; color: #000; border: 1px solid #d9dfb4; font-weight: 600;}
.bottom_block .click_here_sec h4{width:68%; text-transform: lowercase;}

.click_here_sec h3 a button{padding: 10px 20px;border: none;font-size: 30px;line-height: 36px;font-weight: 600;display: block;background-color: transparent;width: 100%;color: #000;display: block;}

@media(max-width:1680px){
    .bottom_innr_block {padding-right: 524px;}
    .bottom_block img{right: 183px;}
}

@media(max-width:1570px){
    .bottom_block .click_here_sec h3, .bottom_block .click_here_sec h4 {
        font-size: 27px;
        line-height: 34px;
     
    }
}
@media(max-width:1480px){
    .bottom_innr_block h1{font-size: 56px;line-height: 75px;   margin-bottom: 0px;}
  
    .bottom_block img {right: 110px;}
    .bottom_innr_block {padding-right: 380px;}
}
@media(max-width:1360px){
    .bottom_innr_block {
        padding-right: 270px;
    }
    .bottom_block img {
        right: 20px;
    }
    .bottom_block .click_here_sec h3, .bottom_block .click_here_sec h4 {
        font-size: 25px;
        line-height: 32px;
    }
}

@media(max-width:1199px){
    .bottom_innr_block {padding:0 20px;}
    .bottom_block img{display: none;}
    .bottom_block .click_here_sec{flex-direction: column;}
    .bottom_innr_block h1{margin-bottom: 8px;text-align: center;}
    .bottom_block .click_here_sec h3{width: auto;text-align: center;margin-bottom: 15px;}
    .bottom_block .click_here_sec h4{width: 100%; text-align: center;}
    .bottom_block .click_here_sec h4 br {display: none;}
    .bottom_block{background-size: cover;}

}
@media(max-width:767px){
    .bottom_block{padding: 12px 0;}
    .bottom_block .click_here_sec h3, .bottom_block .click_here_sec h4 {
        font-size: 24px;
        line-height: 32px;
    }
    .bottom_block .click_here_sec h3{margin-bottom: 12px;}
    .bottom_innr_block h1 { font-size: 40px; line-height: 60px;}

}
@media (max-width: 520px){
    .bottom_block .click_here_sec h3, .bottom_block .click_here_sec h4 {
        font-size: 20px;
        line-height: 27px;
    }
    .bottom_innr_block h1 {
        font-size: 32px;
        line-height: 40px;
    }
}