.home img {display: block; width: 100%;}



/* -----------------------------CSS Starts---------------------------- */

.home_banner_main_wrapper {background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/IWC_home_banner_updt.webp);background-repeat: no-repeat;background-position: left top;background-size: cover;}
.home_banner_wrapper {display: flex;justify-content: space-between;padding-top: 20px;padding-bottom: 15px;}
.banner_left_section {width: 65%;}
.banner_left_section h1 {text-transform: uppercase;    margin-top: 20px;line-height: 72px;
    font-size: 72px;font-weight: var(--font-bold);background-color: #24b0d8;color: transparent;text-shadow: 2px 2px 3px rgb(61 145 185 / 30%), -1px -1px #37c4e7;-webkit-background-clip: text;}
.banner_left_section h2 {font-size: 36px;line-height: 40px;    margin-top: 20px;
    font-weight: var(--font-medium);color: #000;text-transform: uppercase;}
.banner_left_section h3 {font-size: 48px;font-weight: var(--font-bold);width: 615px;color: #ffffff;margin-top: 30px;font-weight: bold;background-size: cover;line-height: 48px;text-shadow: 0 0 2px #235a66;padding: 20px;border: solid 1px #fff;background: url('https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iwc_top_bannerText_BG1.webp') no-repeat left bottom;}

.banner_left_section h4 {font-size: 24px;line-height: 30px;  margin-top: 30px; font-weight:bold; color: #000;  width: 150%; }

.banner_desc_wrapper {position: relative;margin-top: 30px;width: 615px;}
.banner_desc_wrapper::after {content: '';width: 660px;height: 10px;position: absolute;background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_home_banner_bluestick.png);border: 1px solid #fff;bottom: -50px;}
.banner_desc_wrapper p {font-size: 19px;font-weight: var(--font-medium);line-height: 24px;}
.banner_desc_wrapper p:not(:first-child) {margin-top: 20px;}

.banner_right_section {width: 26%;padding: 10px;background: #11bbd6;border: 1px solid #fff;border-radius: 5px;}
.banner_right_section .backend_form_headwrapp {padding: 12px;background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_home_banner_formhead_bg.png);background-repeat: no-repeat;background-size: cover;border: 1.5px solid #fff;border-radius: 3px;margin-bottom: 22px;}
.banner_right_section h2 {text-align: center;font-size: 30px;font-weight: var(--font-bold);color: #fff;text-transform: uppercase;line-height: 36px;}
.banner_right_section .formCls .formInline {padding: 0 15px;}
.banner_right_section .formCls .formInline .inputBlock {margin-bottom: 10px;}
.banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root {width: 100%;}
.banner_right_section .formCls .formInline .inputBlock .MuiInputBase-root, .banner_right_section .formCls .formInline .usertypebox .MuiSelect-select {border-radius: 0;position: relative;}
.banner_right_section .formCls .formInline .inputBlock .MuiInputBase-root .MuiInputBase-input {padding: 10px 14px;background-color: #fff;color: #303232;font-size: 18px;font-weight: var(--font-medium);}
.banner_right_section .formCls .formInline .inputBlock .MuiInputBase-root .MuiFormLabel-root.MuiInputLabel-root {top: -2px;}
.banner_right_section .formCls .formInline .inputBlock .MuiInputBase-root .MuiInputLabel-shrink {top: 5px;}
.banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormLabel-root {top: -5px;}
.banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiOutlinedInput-notchedOutline {border-color: #afadad;top: 0;}
.banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiOutlinedInput-notchedOutline legend {display: none;}
.banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiInputLabel-shrink {display: none;}
.banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormLabel-colorPrimary {color: #303232;font-size: 18px;font-weight: var(--font-medium);}
.banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormHelperText-root {background: #d32f2f;border-radius: 4px;color: #fff;font-weight: 700;left: -165px;margin: 0;padding: 2px 10px;position: absolute;top: 0;width: 140px;}
.banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormHelperText-root::after {background: #d32f2f;-webkit-clip-path: polygon(0 0,0 100%,100% 100%);clip-path: polygon(0 0,0 100%,100% 100%);content: "";display: block;height: 10px;position: absolute;right: -8px;top: 8px;width: 10px;}
.banner_right_section .formCls .formInline .usertypebox .MuiSvgIcon-root {display: none;}
.banner_right_section .formCls .formInline .multipleCheckbox .MuiFormLabel-root div {font-size: 27px;font-weight: var(--font-semibold);color: #000;text-shadow: 0px 1px 1px #1ad7f5;margin-top: 6px;}
.banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root {display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 8px;justify-content: space-between;}
.banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiFormControlLabel-root {width: 50%;margin: 0;align-items: flex-start;margin-bottom: 5px;}
.banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiTypography-root {font-size: 18px;color: #fff;font-weight: var(--font-medium);margin-left: 12px;line-height: 20px;}
.banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiFormControlLabel-root .MuiButtonBase-root {background-color: #fff;border-radius: 0;width: 1em;height: 1em;box-shadow: 0 2px 4px #b5a6a6 inset;}
.banner_right_section .formCls .formInline .radio .MuiFormLabel-root div {font-size: 18px;color: #fff;font-weight: var(--font-medium);}
.banner_right_section .formCls .formInline .radio {display: flex;align-items: center;}
.banner_right_section .formCls .formInline .radio .MuiFormControl-root {margin-left: 20px;}
.banner_right_section .formCls .formInline .radio .MuiFormControl-root .MuiFormGroup-root {flex-direction: row;}
.banner_right_section .formCls .formInline .radio .MuiFormControl-root .MuiFormGroup-root .MuiTypography-root {color: #fff;font-size: 18px;font-weight: var(--font-medium);}
.banner_right_section .formCls .formInline .radio .MuiFormControl-root .MuiFormGroup-root .Mui-checked {color: #6ae24f;}
.banner_right_section .formCls .formInline .submitbtnsection {background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_home_banner_formhead_bg_updted.webp);background-color: #000;background-repeat: no-repeat;background-size: cover;border: 1px solid #00dcff;margin-top: 15px;margin-left: -15px;margin-right: -15px;}
.banner_right_section .formCls .formInline .submitbtnsection .MuiButtonBase-root {padding: 0;width: 100%;}  
.banner_right_section .formCls .formInline .submitbtnsection .MuiButtonBase-root span {font-size: 35px;color: #fff;}

.home_block1 .block1_grid_wrapp {display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;}
.grid_image img {display: block;width: 100%;}
.home_block1 .home_block1_grid {width: 33%;position: relative;background-position: left top;}
.home_block1_grid .grid_heading{padding: 17px 25px;text-align: center;background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_home_block1_heading_bg.webp);background-repeat: no-repeat;background-size: cover;background-position: center;    border-bottom: 1px solid #fff;}
.grid_heading p {background: linear-gradient(180deg, rgba(252,252,252,1) 37%, rgba(206,207,207,1) 70%);font-size: 40px;font-weight: var(--font-bold);color: transparent;-webkit-background-clip: text;    line-height: 52px;}
.grid_button button {padding: 8px 10px;font-size: 24px;text-transform: uppercase;font-weight: var(--font-semibold);background: linear-gradient(180deg, rgba(252,252,252,1) 21%, rgba(206,207,207,1) 82%);color: transparent;-webkit-background-clip: text;}
.grid_button {background: linear-gradient(180deg, rgba(65,78,84,1) 37%, rgba(7,9,9,1) 70%);}
.grid_button_position {position: absolute;right: 0;bottom: 0;}

.block2_banner_wrapper {padding: 14px 14px 14px 40px;background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_home_blockbanner_bg.webp);background-repeat: no-repeat;background-size: cover;background-position: center;display: flex;justify-content: space-between;align-items: center;}
.block2_banner_wrapper h1 {font-size: 66px;font-weight: var(--font-bold);color: #fff;text-shadow: 1px 3px 5px #2aafd1;}
.home_block2 .grid_button {width: fit-content;margin: 0 0 0 auto;}
.home_block2 .block2_banner_logo_wrapper {width: 48%;display: flex;}
.home_block2 .block2_banner_logo_wrapper .banner_logo_wrap {width: 43%;background-color: #fff;padding-top: 15px;padding-left: 15px;display: flex;flex-direction: column;justify-content: space-between;}
.logo_wrapper_differ {background-color: #ebf1f5 !important;}
.home_block2 .block2_banner_logo_wrapper .banner_logo_wrap:nth-of-type(1) {box-shadow: 0px 0px 5px 4px #13a5bc;}
.home_block2 .block2_banner_logo_wrapper .banner_logo_wrap:nth-of-type(2) {padding-top: 0;width: 51%;margin-left: 13px;}

.home_block4 {background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_home_block2_bgimg.webp);background-size: auto 100%;background-position: right -140px top;background-repeat: no-repeat;}
.home_block4 .home_block4_content_wrap {padding-right: 1000px;}
.home_block4 .textwrapper p {color: #000;}

.home_block5 {padding-bottom: 60px;
    position: relative;}
.home_block5 .home_block5_wrapper {padding: 25px 15px 25px 25px;background-image:
    /* url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_lastblock_arrow.png), */
    url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_last_block_bg.webp); 
    background-position:center;background-size:cover;background-repeat: no-repeat;justify-content: space-between;display: flex;align-items: center;position: relative;
}
.home_block5 .home_block5_wrapper > img {position: absolute;right: 10px;bottom: 20px;}.home_block5 .home_block5_leftimage_wrapper {width: 45%;}
.home_block5 .home_block5_leftimage_innerwrapper img {display: block;width: 100%;border: 1px solid #fff;}
.home_block5 .home_block5_leftimage_innerwrapper {padding: 20px;background-color: #36d5f8;box-shadow: 0px 0px 8px 2px #049ec8;}
.home_block5 .home_block5_content_wrapper {width: 50%;position: relative;}
.home_block5 .home_block5_content_wrapper p {font-size: 60px;color: #fff;line-height: 65px;font-weight: var(--font-semibold);    padding-right: 70px;position: relative;}
.home_block5 .home_block5_content_wrapper::before {content: '';height: 100%;width: 10px;position: absolute;background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_home_lastblock_greenstick.png);left: -45px;}
/* .home_block5 .home_block5_content_wrapper p::after {content: '';height: 100%;width: 500px;position: absolute;background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_lastblock_arrow.png);right: -45px;top: 50%;transform: translateY(-50%);} */



.beside_arrow_div_homelast {position: absolute;height: 100%;width: 7%;right: 0;cursor: pointer;
}
.home_bottom_btn {display: block;margin-left: auto;font-size: 30px;border: none;padding: 10px 30px;border-radius: 10px;margin-bottom: 20px;
    margin-right: 50px;font-weight: 600;
    background: linear-gradient(180deg, rgba(65,78,84,1) 37%, rgba(7,9,9,1) 70%);
    color: #fff;}
/* -----------------------------CSS Ends---------------------------- */

/* -----------------------------Res Starts---------------------------- */

@media only screen and (max-width:1838px) {
    .banner_right_section h2 {font-size: 24px;line-height: 30px;}
    .home_block1_grid .grid_heading p {font-size: 32px;line-height: 42px;}
    .grid_button button {font-size: 20px;}
    .home_block5 .home_block5_content_wrapper p {font-size: 56px;line-height: 61px;padding-right: 90px;}
    .home_block5 .home_block5_wrapper > img {    bottom: 28px;
    }
}

@media only screen and (max-width:1800px) {
    .block2_banner_wrapper h1 {font-size: 62px;}
    .home_block4 .home_block4_content_wrap {padding-right: 54%;}
    .home_block4 {background-position: right -270px top;}
    .home_block5 .home_block5_content_wrapper p {font-size: 50px;}

}

@media only screen and (max-width:1768px) {
    .home_banner_main_wrapper {background-position: left 50% top;}
    .banner_left_section h1 {font-size: 68px;line-height: 70px;}
    .banner_left_section h2 {font-size: 34px;line-height: 38px;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormLabel-root div {font-size: 24px;}
    .banner_left_section h3 {font-size: 46px;line-height: 46px;}
    .banner_desc_wrapper {width: 585px;}

    .banner_left_section h4{ margin-top: 20px;}
    .banner_desc_wrapper::after{ bottom: -30px;}
    .home_block5 .home_block5_wrapper > img {
        bottom: 24px;
    }
}

@media only screen and (max-width:1728px) {
    .home_banner_main_wrapper {background-size: cover;}
    .banner_left_section h1 {font-size: 64px;line-height: 66px;}
    .banner_left_section h2 {font-size: 32px;line-height: 36px;}
    .banner_left_section h3 {font-size: 42px;line-height: 42px;width: 565px;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormLabel-root div {font-size: 22px;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiTypography-root {font-size: 16px;margin-left: 10px;}
    .banner_right_section .formCls .formInline .inputBlock .MuiInputBase-root .MuiInputBase-input {padding: 8px 10px;        border-width: 1px !important;}
    .banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormLabel-root {top: -7px;}
    .banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormLabel-colorPrimary {font-size: 16px;}
    .banner_right_section .formCls .formInline .inputBlock {margin-bottom: 8px;}
    .banner_right_section .backend_form_headwrapp {margin-bottom: 18px;}
    .banner_desc_wrapper::after {width: 605px;}
    .block2_banner_wrapper h1 {font-size: 52px;}
    .banner_left_section h2{ margin-top: 12px;}
    .banner_left_section h3{ margin-top: 16px;}
    .home_block5 .home_block5_content_wrapper p {font-size: 46px;line-height: 58px;}
    .home_block5 .home_block5_wrapper > img {width: 23%;}
}

@media only screen and (max-width:1648px) {
    .banner_left_section h3 {width: 525px;}
    .banner_desc_wrapper {width: 535px;}
    .banner_desc_wrapper::after {width: 650px;}
}

@media only screen and (max-width:1620px) {
    .home_block4 {background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_home_twofemale_blockimg.webp);background-position: right -150px top;}
}

@media only screen and (max-width:1598px) {
    .home_block5 .home_block5_content_wrapper p {font-size: 46px;line-height: 55px;}
    .home_block5 .home_block5_wrapper > img {width: 25%;    right: 5px;}
}

@media only screen and (max-width:1548px) {
    .banner_right_section h2 {font-size: 20px;line-height: 26px;}
    .banner_left_section h1 {font-size: 58px;line-height: 60px;}
    .banner_left_section h2 {font-size: 30px;line-height: 34px;}
    .banner_left_section h3 {font-size: 38px;line-height: 38px;width: 465px;}   
    .banner_desc_wrapper p {font-size: 19px;line-height: 23px;}
    .banner_desc_wrapper::after {width: 630px;}
    .banner_desc_wrapper {width: 475px;}
    .home_block1_grid .grid_heading p {font-size: 28px;line-height: 38px;}
    .grid_button button {font-size: 18px;}
}
@media only screen and (max-width:1520px) {
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiTypography-root {font-size: 14px;margin-left: 8px;}
    .home_block5 .home_block5_content_wrapper p {
        font-size: 42px;
        line-height: 50px;
    }
}

@media only screen and (max-width:1499px) {   
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiFormControlLabel-root {width: 48%;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiFormControlLabel-root .MuiButtonBase-root {width: 0.2em;height: 0.2em}
    .home_block2 .block2_banner_logo_wrapper {width: 49%;}
    .block2_banner_wrapper h1 {font-size: 46px;}
}

@media only screen and (max-width:1480px) {
    .home_block5 .home_block5_content_wrapper p {font-size: 39px;line-height: 49px;}
    .home_block5 .home_block5_content_wrapper::before {left: -35px;}

    .banner_desc_wrapper{ margin-top: 20px;}
}

@media only screen and (max-width:1420px) {
    .banner_left_section h3 {font-size: 30px;line-height: 32px;width: 435px;}
    .banner_desc_wrapper {width: 405px;}
    .banner_desc_wrapper::after {display: none;}
    .banner_left_section h2 {font-size: 26px;line-height: 30px;}
    .banner_desc_wrapper p:not(:first-child) {margin-top: 14px;}
    .banner_desc_wrapper p {font-size: 18px;line-height: 23px;}
    .banner_left_section h4 {width: 180%;}
}

@media only screen and (max-width:1398px) {
    .banner_left_section h1 {font-size: 54px;}  
    .banner_left_section h2 {font-size: 28px;line-height: 32px;}
    .banner_left_section h3 {font-size: 32px;line-height: 32px;width: 385px;}
    .banner_desc_wrapper {width: 385px;}
}

@media only screen and (max-width:1355px) {
    .home_banner_main_wrapper {background-position: left 50% top;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormLabel-root div {font-size: 20px;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiTypography-root {font-size: 13px;margin-left: 7px;line-height: 21px;}
    .home_block1_grid .grid_heading p {font-size: 24px;line-height: 34px;}
    .grid_button button {font-size: 16px;}
    .block2_banner_wrapper h1 {font-size: 42px;}
    .home_block4 {background-position: right -230px top;}
    .home_block5 .home_block5_content_wrapper p {font-size: 35px;line-height: 45px;}
}

@media only screen and (max-width:1299px) {
    .banner_right_section h2 {font-size: 18px;line-height: 22px;}
    .home_banner_main_wrapper {background-position: left 50% top;}
    .home_block5 .home_block5_content_wrapper p {font-size: 35px;line-height: 45px;}
    .banner_left_section h2 {font-size: 26px;line-height: 30px;}   
    .banner_left_section h4{   margin-top: 12px;}
   
}

@media only screen and (max-width:1268px) {
    .banner_left_section h1 {font-size: 50px;line-height: 56px;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root {flex-direction: column;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiFormControlLabel-root {width: 100%;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiFormControlLabel-root:not(:last-child) {margin-bottom: 4px;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiTypography-root {font-size: 15px;}
    .banner_right_section .formCls .formInline .inputBlock .MuiInputBase-root .MuiInputBase-input {padding: 5px 10px;}
    .banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormLabel-colorPrimary {font-size: 14px;}
    .banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormLabel-root {top: -8px;}
    .banner_right_section .formCls .formInline .inputBlock {margin-bottom: 4px;}
    .banner_right_section .backend_form_headwrapp {margin-bottom: 10px;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormLabel-root div {font-size: 18px;}
    .banner_right_section .formCls .formInline {padding: 0;}
    .banner_right_section .formCls .formInline .submitbtnsection {margin-left: 0;margin-right: 0;}
    .banner_right_section .formCls .formInline .radio {display: block;margin-top: 10px;}
    .banner_right_section .formCls .formInline .radio .MuiFormControl-root {margin-left: 0;}
}

@media only screen and (max-width:1228px) {
    .home_block4 {background-position: right -260px top;}
    .home_block5 .home_block5_content_wrapper p {font-size: 33px;line-height: 43px;}
}

@media only screen and (max-width:1199px) {
    .home_banner_main_wrapper {background: linear-gradient(170deg, rgba(219,237,249,1) 18%, rgba(135,182,211,1) 88%);}
    .home_banner_wrapper {flex-direction: column;}
    .banner_left_section {width: 100%;}
    .banner_left_section h3 {    width: auto;background-size: cover;  margin-top: 20px;  }
    .banner_desc_wrapper {width: 100%;margin-top: 20px;}
    .banner_right_section {width: auto;margin-top: 30px;}
    .banner_left_section h2 br {display: none;}
    .banner_right_section h2 {font-size: 34px;line-height: 38px;}
    .banner_right_section .formCls .formInline .inputBlock .MuiInputBase-root .MuiInputBase-input {padding: 10px 10px;}
    .banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormLabel-colorPrimary {font-size: 18px;}
    .banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormLabel-root {top: -6px;}
    .banner_right_section .formCls .formInline .inputBlock {margin-bottom: 6px;}
    .banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormHelperText-root {border-radius: 0;left: 0;margin: 0;position: inherit;top: 0;width: auto;}
    .banner_right_section .formCls .formInline .inputBlock .MuiFormControl-root .MuiFormHelperText-root::after {content: unset;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormLabel-root div {font-size: 26px;margin-top: 0;}
    .banner_right_section .formCls .formInline .multipleCheckbox {margin-top: 20px;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root {flex-direction: row;margin-top: 12px;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiFormControlLabel-root {width: 50%;margin-bottom: 10px !important;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiTypography-root {font-size: 21px;}
    .banner_right_section .formCls .formInline .radio .MuiFormLabel-root div {font-size: 21px;}
    .banner_left_section h1 {margin-top: 0;}
    .home_block1_grid .grid_heading p {font-size: 20px;line-height: 24px;}
    .grid_button button {font-size: 14px;}
    .block2_banner_wrapper {flex-direction: column;padding: 0px 14px 14px 14px;}
    .home_block2 .block2_banner_logo_wrapper {width: 100%;justify-content: center;margin-top: 5px;}
    .home_block2 .block2_banner_logo_wrapper .banner_logo_wrap {width: 48%;}
    .home_block2 .block2_banner_logo_wrapper .banner_logo_wrap:nth-of-type(2) {width: 48%;}
    .home_block4 {background-position: left 52% top;background-size: 154%;background-image: url(https://d37pbainmkhx6o.cloudfront.net/wound-care-for-home_Assets/iwc_header/IWC_home_block2_bgimg.webp);}
    .home_block4 .home_block4_content_wrap {padding-right: 0;}
    .home_block4 {padding-top: 46%;}
    .home_block5 .home_block5_wrapper {flex-direction: column;background: linear-gradient(337deg, rgba(5,192,218,1) 15%, rgba(2,125,164,1) 82%);padding: 25px;}
    .home_block5 .home_block5_leftimage_wrapper {width: 100%;}
    .home_block5 .home_block5_content_wrapper::before {content: unset;}
    .home_block5 .home_block5_content_wrapper p {padding-right: 0;line-height: 40px;}
    .home_block5 .home_block5_content_wrapper {width: 100%;margin-top: 15px;}
    .home_block5 {padding-bottom: 2%;}
    .banner_right_section .formCls .formInline .submitbtnsection .MuiButtonBase-root span {font-size: 35px;}
    .banner_right_section .formCls .formInline .submitbtnsection {margin-left: 0;margin-right: 0;width: 350px;margin: 12px auto 0;}
    .banner_left_section h4 {width: auto;margin-top: 20px;}
    .home_block5 .home_block5_wrapper > img, .beside_arrow_div_homelast {display: none;}
}

@media only screen and (max-width:1020px) {
    .home_block1 .block1_grid_wrapp {flex-direction: column;}
    .home_block1 .home_block1_grid {width: 100%;}
    .home_block1 .home_block1_grid:not(:first-child) {margin-top: 13px;}
    .home_block2 .block2_banner_logo_wrapper .banner_logo_wrap, .home_block2 .block2_banner_logo_wrapper .banner_logo_wrap:nth-of-type(2) {width: 45%;}
}

@media only screen and (max-width:799px) {
    .banner_left_section h1 {font-size: 44px;}
    .banner_left_section h2 {font-size: 25px;line-height: 29px;}

    .banner_left_section h4{font-size: 25px;line-height: 29px;}
}

@media only screen and (max-width:620px) {
    .banner_left_section h1 {font-size: 40px;}
    .banner_left_section h1 {font-size: 38px;}
    .banner_left_section h2 {font-size: 23px;line-height: 27px;}
    .banner_left_section h3 {font-size: 27px;line-height: 29px;}
    .banner_desc_wrapper p {font-size: 18px;line-height: 22px;}
    .banner_right_section h2 {font-size: 30px;line-height: 32px;}
    .block2_banner_wrapper h1 {font-size: 32px;}

    .banner_left_section h4{font-size: 23px;line-height: 27px;}
}

@media only screen and (max-width:599px) {
    .home_block4 .home_block4_content_wrap p {font-size: 19px;line-height: 26px;}
    .home_block5 .home_block5_wrapper, .home_block5 .home_block5_leftimage_innerwrapper {padding: 12px;}
    .home_block5 .home_block5_content_wrapper p {line-height: 30px;font-size: 26px;}
    .home_block5 .home_block5_content_wrapper {margin-top: 12px;}
    .home_block5 {padding-bottom: 4%;}
}

@media only screen and (max-width:548px) {
    .banner_left_section h1 {font-size: 34px;line-height: 38px;}
    .banner_left_section h2 {font-size: 21px;line-height: 25px;}
    .banner_left_section h3 {font-size: 25px;line-height: 27px;}
    .banner_right_section h2 {font-size: 26px;line-height: 28px;}

    .banner_left_section h4{font-size: 21px;line-height: 25px;}
}

@media only screen and (max-width:520px) {
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root {flex-direction: column;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiTypography-root {font-size: 17px;}
}

@media only screen and (max-width:499px) {
    .block2_banner_wrapper {padding: 10px 14px 14px 14px;}
    .block2_banner_wrapper h1 {text-align: center;line-height: 36px;}
    .home_block2 .block2_banner_logo_wrapper {margin-top: 12px;flex-direction: column;}
    .home_block2 .block2_banner_logo_wrapper .banner_logo_wrap, .home_block2 .block2_banner_logo_wrapper .banner_logo_wrap:nth-of-type(2) {width: 100%;padding: 0;}
    .home_block2 .block2_banner_logo_wrapper .banner_logo_wrap:not(:first-child) {margin-top: 14px;margin-left: 0;}
    .home_block2 .grid_button {margin: 0 auto;margin-top: 10px;}
    .home_block2 .grid_button button {font-size: 18px;}
    .home_block4 {background-position: left 53% top;background-size: 165%;padding-top: 50%;}
}

@media only screen and (max-width:480px) {
    .banner_right_section h2 {font-size: 25px;line-height: 27px;}
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormLabel-root div {font-size: 24px;white-space: pre-wrap;line-height: 26px;}
}

@media only screen and (max-width:430px) {
    .banner_right_section h2 {font-size: 24px;line-height: 26px;}
}

@media only screen and (max-width:420px) {
    .banner_right_section .formCls .formInline .multipleCheckbox .MuiFormControl-root .MuiFormControlLabel-root {width: 100%;}
    .home_block5 .home_block5_content_wrapper p {line-height: 26px;font-size: 21px;}  
    .banner_right_section .formCls .formInline .submitbtnsection {width: inherit;}
    .home_block1_grid .grid_heading {padding: 8px 16px;}
}

/* -----------------------------CSS Starts---------------------------- */
